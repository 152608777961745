

import { defineComponent, Ref, ref, watch } from 'vue';

import MainContent from '@/components/layout/main_content.vue';
import BigCard from '@/components/shared/big_card.vue';
import Card from '@/components/shared/card.vue';
import GreenTitle from '@/components/shared/green_title.vue';
import CarouselTest from '@/components/home/carousel/news-carousel.vue';

import { setBreadcrumbs } from '@/utils/breadcrumbs';

import News from '@/model/news';
import HomePanels from '@/model/home_panels';

interface dataI {
  slide: Ref<number>;
  news: Ref<News[]>;
  selectedNewsId: Ref<Number>;
  homePanels: Ref<HomePanels[]>;
}

export default defineComponent({
    components: {
        MainContent,
        GreenTitle,
        Card,
        CarouselTest,
        BigCard
    },
    props: {
        title: {
            type: String,
            required: true
        }

    // SelectedNews: Function
    },
    setup(): dataI {
        return {
            selectedNewsId: ref(0),
            news: ref([]),
            slide: ref(1),
            homePanels: ref([])
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'hide', path: '' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Home.Title', 'Home.Subtitle');
            }
        },

        getLocalePanel(i: number) {
            if (this.homePanels[i]) {
                return this.homePanels[i];
            }
            return null;
        }
    },
    mounted() {
        this.updateSubNavBar();
    },
    computed: {
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    },
    async beforeMount() {
        await News.init().then((news: Ref<News[]>) => (this.news = news.value));
        await HomePanels.init().then(
            (homePanels: Ref<HomePanels[]>) => {
                this.homePanels = homePanels.value;
                watch(homePanels, panels => {
                    this.homePanels = panels;
                });
            });
    }
});
