
import MainContent from '@/components/layout/main_content.vue';
import GreenTitle from '@/components/shared/green_title.vue';
import Product, { IProductCategory } from '@/model/product';
import BigCard from '@/components/shared/big_card.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

// @ts-ignore
import { marked } from 'marked';

import { ref, Ref, defineComponent, watch } from 'vue';

interface DataI {
    products: Ref<Product[]>,
    categories: Ref<{[key: string]: IProductCategory}>,
    slide: Ref<number>
    brochures: Ref<any>
}

export default defineComponent({
    components: {
        MainContent,
        GreenTitle,
        BigCard
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data(): DataI {
        return {
            products: ref([]),
            categories: ref({}),
            slide: ref(1),
            brochures: ref({})
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'ProductCatalog.Title', path: 'product' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'ProductCatalog.Title', 'ProductCatalog.PresentationSection.Title');
            }
        },
        compileMarkDown(text: string): string {
            return marked.parse(text);
        },
        async getBrochures() {
            const brochures = await Product.getBrochures();
            return brochures;
        }
    },
    computed: {
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        },
        getFileFullUrl(): (url: string) => string {
            return (url: string) => {
                return `${process.env.VUE_APP_API_URL?.replace('/api', '')}${url}`;
            };
        }
    },
    async beforeMount() {
        const brochures = await this.getBrochures();

        this.brochures = brochures;
    },
    async mounted() {
        const prodRef = (await Product.init());

        this.products = prodRef.value;
        watch(prodRef, prods => {
            this.products = prods;
        });
        this.categories = Product.productCategories.value;

        this.updateSubNavBar();
    }
});
