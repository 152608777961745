import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { extractLocalizedId } from './helper/locale';
import { IUser, IAddress, IMedicin, fromDataType } from './helper/interface';
import { TransportSolution } from './transports';


export class CompatibilityRequest {

    id: string | undefined;
    institute: string = '';
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    position: string = '';
    comment: string = '';
    status: string = '';
    transportSolution: TransportSolution | null = null;
    infusionType: string = '';
    requester: IUser;
    reviewer: IUser;
    lang: string = '';
    address: IAddress;
    medicins: IMedicin[] = [];
    createdAt: string = (new Date()).toISOString();
    updatedAt: string = (new Date()).toISOString();

    constructor(
        id: number | string | null,
        institute: string,
        firstName: string,
        lastName: string,
        email: string,
        position: string,
        comment: string,
        status: string,
        transportSolution: TransportSolution | null,
        infusionType: string,
        requester: IUser,
        reviewer: IUser,
        lang: string,
        address: IAddress,
        medicins: IMedicin[],
        createdAt: string,
        updatedAt: string
    ) {
        if (id !== null) this.id = String(id);
        this.institute = institute || this.institute;
        this.firstName = firstName || this.firstName;
        this.lastName = lastName || this.lastName;
        this.email = email || this.email;
        this.position = position || this.position;
        this.comment = comment || this.comment;
        this.status = status || this.status;
        this.transportSolution = transportSolution;
        this.infusionType = infusionType || this.infusionType;
        this.requester = requester;
        this.reviewer = reviewer;
        this.lang = lang || this.lang;
        this.address = address;
        this.medicins = medicins || this.medicins;
        this.createdAt = createdAt || (new Date()).toISOString();
        this.updatedAt = updatedAt || (new Date()).toISOString();
    }

    async save(fields?: string[]) :Promise<CompatibilityRequest> {
        await authReady;
        const body = { data: this.toJSON() };
        if (fields) {
            for (const k in body.data) {
                if (fields.indexOf(k) < 0) {
                    delete body.data[k];
                }
            }
        }
        const res = await fetch(process.env.VUE_APP_API_URL + '/compatibility-requests' + (this.id ? '/' + this.id : ''), {
            headers: {
                'Authorization': 'Bearer ' + currentJwt.value,
                'Content-Type': 'application/json'
            },
            method: this.id ? 'PUT' : 'POST',
            body: JSON.stringify(body)

        });

        const data = (await res.json()).data;
        Object.assign(this, {
            status: data.attributes.status
        });
        return this;

    }

    toJSON() :any {
        return {
            institute: this.institute,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            position: this.position,
            comment: this.comment,
            status: this.status,
            transportSolution: this.transportSolution?.id,
            infusionType: this.infusionType,

            // Requester: this.requester?.id, // Error 500 on server call
            reviewer: this.reviewer?.id,
            lang: this.lang,
            address: this.address,
            medicins: this.medicins,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        };
    }

    static requests : Ref<CompatibilityRequest[]> = ref([]);

    static async init() :Promise<Ref<CompatibilityRequest[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/compatibility-requests?populate=*', {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;

        this.requests.value.splice(0);
        for (let i = 0; i < data.length; i++) {
            const rawC = fromDataType(data[i]);
            const c = CompatibilityRequest.from(rawC);
            this.requests.value.push(c);
        }

        return this.requests;
    }

    static from(raw: any): CompatibilityRequest {
        return new CompatibilityRequest(
            raw.id,
            raw.institute,
            raw.firstName,
            raw.lastName,
            raw.email,
            raw.position,
            raw.comment,
            raw.status,
            raw.transportSolution ? new TransportSolution(raw.transportSolution.id, raw.transportSolution.name, raw.transportSolution.description, raw.transportSolution.isContainer, raw.transportSolution.isCoinfusion, '') : null,
            raw.infusionType,
            raw.requester,
            raw.reviewer,
            raw.lang,
            raw.address,
            raw.medicins,
            raw.createdAt,
            raw.updatedAt
        );
    }


}


export default CompatibilityRequest;
