
import { defineComponent } from 'vue';

import { IMedicin } from '@/model/helper/interface';

export default defineComponent({
    name: 'PlanModal',
    props: ['medicins'],
    data() {
        return {
            showModal: false,
            models: {}
        };
    },
    beforeMount() {
        this.models = this.medicins.map((medicin: IMedicin) => {
            return { medicin, value: false };
        });
    }
});
