<template>
    <div>
        <div v-for="(request, index) in [tmpData]" :key="request.id">
            <Subtitle :title="`${$t('Requests.TestedPreparation')} ${index + 1}`" />
            <div class="text-primary q-ma-sm q-mt-lg">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditive") }}</div>
            <div class="q-mt-md">
                <div
                    class="bg-grey q-my-xs q-mx-sm q-py-xs q-px-md"
                    style="border-radius: 50px;">
                    <div class="text-capitalize text-bold text-dark text-subtitle1">{{ request.compatibility.medicin.name + ' - ' + request.compatibility.medicin.activeAgent }}</div>
                </div>
            </div>

            <!-- Medicin infos -->
            <div class="row justify-between">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Compatibility.SelectDrug.TradeNameButton") }}</div>
                    <q-input v-model="request.compatibility.medicin.name" :placeholder="request.compatibility.medicin.name" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditiveTab.ActiveIngredient") }}</div>
                    <q-input v-model="request.compatibility.medicin.activeAgent" :placeholder="request.compatibility.medicin.activeAgent" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
            </div>

            <div class="col q-mr-sm">
                <div class="text-primary q-ma-sm">{{ $t("Requests.DrugSolutionConcentration") }}</div>
                <q-input v-model="request.compatibility.preparation" :placeholder="request.compatibility.preparation" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
            </div>

            <div class="row justify-between">
                <div class="col-6 q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditiveTab.ActiveIngredient") }} mg/mL {{ $t("Or") }} IE/mL</div>
                    <q-input v-model="request.compatibility.agentConcentration" @change="(value) => {
                        if (value.match('[0-9]+,[0-9]+')) {
                            value = value.replace(',', '.');
                        }

                        if (!isNaN(value)) {
                            request.compatibility.agentConcentration = value;
                        }
                    }" :rules="[ val => !isNaN(val) || `Should be a number`]" :placeholder="request.compatibility.agentConcentration" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
            </div>

            <!-- Infusionsrate 1 -->
            <div class="text-primary q-mx-sm q-mt-lg text-weight-bold">{{ $t("Requests.PerfusionRate") }} {{ $t("Requests.Combination") }} 1</div>
            <div class="row justify-between">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Requests.Preparation") }} Minimal (mL/Min.)</div>
                    <q-input v-model="request.compatibility.minimalAgent" @change="(value) => {
                        if (value.match('[0-9]+,[0-9]+')) {
                            value = value.replace(',', '.');
                        }

                        if (!isNaN(value)) {
                            request.compatibility.minimalAgent = value;
                        }
                    }" :rules="[ val => !isNaN(val) || `Should be a number`]" :placeholder="request.compatibility.minimalAgent" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Compatibility.CarrierSolutions.Title") }} Maximal (mL/Min.)</div>
                    <q-input v-model="request.compatibility.maximalSolution" @change="(value) => {
                        if (value.match('[0-9]+,[0-9]+')) {
                            value = value.replace(',', '.');
                        }

                        if (!isNaN(value)) {
                            request.compatibility.maximalSolution = value;
                        }
                    }" :rules="[ val => !isNaN(val) || `Should be a number`]" :placeholder="request.compatibility.maximalSolution" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
            </div>

            <!-- Infusionsrate 2 -->
            <div class="text-primary q-mx-sm q-mt-lg text-weight-bold">{{ $t("Requests.PerfusionRate") }} {{ $t("Requests.Combination") }} 2</div>
            <div class="row justify-between">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Requests.Preparation") }} Maximal (mL/Min.)</div>
                    <q-input v-model="request.compatibility.maximalAgent" @change="(value) => {
                        if (value.match('[0-9]+,[0-9]+')) {
                            value = value.replace(',', '.');
                        }

                        if (!isNaN(value)) {
                            request.compatibility.maximalAgent = value;
                        }
                    }" :rules="[ val => !isNaN(val) || `Should be a number`]" :placeholder="request.compatibility.maximalAgent" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Compatibility.CarrierSolutions.Title") }} Minimal (mL/Min.)</div>
                    <q-input v-model="request.compatibility.minimalSolution" @change="(value) => {
                        if (value.match('[0-9]+,[0-9]+')) {
                            value = value.replace(',', '.');
                        }

                        if (!isNaN(value)) {
                            request.compatibility.minimalSolution = value;
                        }
                    }" :rules="[ val => !isNaN(val) || `Should be a number`]" :placeholder="request.compatibility.minimalSolution" v-bind:readonly="editor" clearable borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 58px" :disable="request.status !== 'scheduled'" />
                </div>
            </div>

            <!-- Bemerkungen -->
            <div class="col q-mr-sm">
                <div class="text-primary q-ma-sm">{{ $t("Compatibility.PhysicoChemicalCompatibility.Results.Comments") }}</div>
                <q-input v-model="request.compatibility.description" :placeholder="request.compatibility.description" v-bind:readonly="editor" borderless class="flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg" style="height: 116px; overflow-y: scroll" :disable="request.status !== 'scheduled'" />
            </div>

            <!-- Kompatibilitat -->
            <Subtitle :title="`${ $t('Requests.PreparationCompatibility') } ${index + 1}`" />
            <div>
                <div class="text-primary q-mx-sm q-mt-lg text-weight-bold">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditiveTab.CombinationEvaluation") }}</div>
                <div class="row no-wrap col-6">
                    <!-- 4 minutes -->
                    <div class="ellipsis col-6">
                        <div class="text-primary q-ma-sm ellipsis">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditiveTab.DirectDetails") }}</div>
                        <div class="no-wrap row">
                            <div class="col-6 q-px-xs">
                                <div class="row items-center justify-center text-bold" style="height: 50px; background: #86FF81; cursor: pointer" v-bind:style="request.compatibility.result4min == true ? '' : 'opacity: 0.2'" @click="request.status === 'scheduled' ? set4min(true) : ''" >+</div>
                            </div>
                            <div class="col-6 q-px-xs">
                                <div class="bg-warning row items-center justify-center text-bold" style="height: 50px; cursor: pointer" v-bind:style="request.compatibility.result4min == false ? '' : 'opacity: 0.2'" @click="request.status === 'scheduled' ? set4min(false) : ''" >-</div>
                            </div>
                        </div>
                    </div>

                    <!-- 60 minutes -->
                    <div class="ellipsis col-6">
                        <div class="text-primary q-ma-sm ellipsis">{{ $t("Compatibility.PhysicoChemicalCompatibility.DrugAdditiveTab.Indirect60") }}</div>
                        <div class="no-wrap row">
                            <div class="col-6 q-px-xs">
                                <div class="row items-center justify-center text-bold" style="height: 50px; background: #86FF81; cursor: pointer"  v-bind:style="request.compatibility.result60min == true ? '' : 'opacity: 0.2'" @click="request.status === 'scheduled' ? set60min(true) : ''" >+</div>
                            </div>
                            <div class="col-6 q-px-xs">
                                <div class="bg-warning row items-center justify-center text-bold" style="height: 50px; cursor: pointer" v-bind:style="request.compatibility.result60min == false ? '' : 'opacity: 0.2'" @click="request.status === 'scheduled' ? set60min(false) : ''" >-</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div v-if="!editor" class="row justify-end" style="gap: 10px; margin-top: 40px">
            <div class="PurpleToWhite q-px-lg q-mt-lg">
                <q-btn no-caps flat
                @click="() => { saveResults() }"
                style="width: 100%"
                class="text-bold text-subtitle text-subtitle1"
                :disable="request.status !== 'scheduled'">
                    {{ speichernMsg }}
                </q-btn>
            </div>
            <div class="PurpleToWhite q-px-lg q-mt-lg">
                <q-btn no-caps flat @click="() => { sendTestReport() }"
                style="width: 100%"
                class="text-bold text-subtitle text-subtitle1"
                :disable="request.status !== 'scheduled'">
                    {{ $t("Requests.SendTestReport") }}
                </q-btn>
            </div>
        </div>
        <div v-else class="row justify-end" style="gap: 10px; margin-top: 40px"></div>
    </div>
</template>

<script>
import CompatibilityPlan from '@/model/compatibility-plan';
import { defineComponent, ref } from 'vue';

const Subtitle = {
    props: ['title'],
    render() {
        return (<div>
            <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{this.title}</div>
            <div class="row">
                <div class="col-3 subtitle-thick" />
                <div class="col-9 subtitle-thin" />
            </div>
        </div>);
    }
};

export default defineComponent({
    props: ['editor', 'request', 'setShowTestReport'],
    data: function() {
        return {
            model: '',
            date: ref(''),
            speichernMsg: this.$t('Requests.SaveDraft'),
            tmpData: ref(CompatibilityPlan.from({ ...this.request }))
        };
    },
    components: {
        Subtitle
    },
    watch: {
        request: {
            handler: function(newValue) {
                if (this.editor) {
                    this.tmpData = CompatibilityPlan.from({ ...newValue });
                }
            },
            deep: true
        }
    },
    methods: {
        async saveResults() {
            this.tmpData.save();
            this.speichernMsg = this.$t('Requests.Saved');
            await new Promise(resolve => setTimeout(resolve, 2000));
            this.speichernMsg = this.$t('Requests.SaveDraft');
        },
        set4min(value) {
            if (!this.editor) {
                this.tmpData.compatibility.result4min = value;
            }
        },
        set60min(value) {
            if (!this.editor) {
                this.tmpData.compatibility.result60min = value;
            }
        },
        async sendTestReport() {

            // TODO Check if all fields are filled
            this.setShowTestReport(true);
        }
    }
});
</script>
