
import GreenTitle from '@/components/shared/green_title.vue';
import ResultsKompat4 from '@/components/kompat/resultsKompat4.vue';
import { defineComponent, Ref, ref } from 'vue';
import Compatibility from '@/model/compatibilities';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
interface dataI {
  CompatibilityTab: Ref<Compatibility[]>;
  isOpen: Ref<number>;
}

export default defineComponent({
    props: [
        'transport',
        'tabOfSelectedMeds',
        'meds_expansion',
        'changeQuantity',
        'perfusionWay'
    ],
    components: {
        GreenTitle,
        ResultsKompat4
    },
    setup(): dataI {
        return {
            CompatibilityTab: ref([]),
            isOpen: ref(0)
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.PhysicoChemicalCompatibility.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }
        }
    },

    mounted() {
        this.updateSubNavBar();
        Compatibility.init(this.transport).then(
            (Compatibility: any) => (this.CompatibilityTab = Compatibility)
        );
    },
    computed: {
        uniqueMed(): Compatibility[] {
            let filteredTab: Compatibility[] = [];
            filteredTab = this.tabOfSelectedMeds.slice();

            filteredTab = filteredTab.reduce((acc: Compatibility[], current: Compatibility) => {
                const medsName = acc.map(med => med.calculation.medicin.name);
                if (medsName.indexOf(current.calculation.medicin.name) === -1) {
                    acc.push(current);
                }
                return acc;
            }, []);

            return filteredTab;
        }
    }
});
