<template>
    <div>
        <!-- Return button -->
        <div class="row justify-between q-mt-md primary-underline">
            <div class="row items-center cursor-pointer" @click="goBack()" style="width: 100px">
                <div class="viewer-back-btn text-accent text-body1 text-weight-bold flex items-center justify-center">&lt;</div>
                <div class="q-ml-md">{{ $t('PreviousButton') }}</div>
            </div>
            <div class="row items-center cursor-pointer btn-click" @click="refreshPage()">
                <div class="viewer-back-btn text-accent text-body1 text-weight-bold flex items-center justify-center">
                    <q-icon name="sync" />
                </div>
                <div class="q-ml-md">{{ $t("Refresh") }}</div>
            </div>
        </div>

        <Subtitle :title="anfragTitle" ></Subtitle>

        <!-- Informations and input selector about the request/plan -->
        <div class="q-my-md">

            <!-- First line -->
            <div class="row justify-between q-mb-lg">

                <!-- Editor name -->
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t('Compatibility.CompatibilityRequest.Editor') }}</div>
                    <div class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ tab == 0 ? currentUser.username : request.reviewerEditor.username }}</div>
                </div>

                <!-- Request status -->
                <div class="col q-ml-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Requests.Status") }}</div>
                    <div class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ request.status }}</div>
                </div>
            </div>

            <!-- Second line -->
            <div class="row">

                <!-- Reviewer name -->
                <div class="col">
                    <div class="text-primary q-ma-sm">{{ $t('Compatibility.CompatibilityRequest.LabTester') }}</div>
                    <div v-if="tab != 0" class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ request.reviewerLaboratory?.username }}</div>
                </div>

                <!-- Request date -->
                <div class="col row justify-between">

                    <!-- Creation date -->
                    <div class="col q-ml-sm">
                        <div class="text-primary q-ma-sm">{{ $t('Requests.CreationDate') }}</div>
                        <div class="flex items-center q-py-md q-pl-sm text-subtitle2">{{ formatedDate(request.createdAt) }}</div>
                    </div>

                    <!-- Edition date, hidden on first tab -->
                    <div class="col q-ml-sm" v-if="tab != 0">
                        <div class="text-primary q-ma-sm">{{ $t('Requests.EditedDate') }}</div>
                        <div class="flex items-center q-py-md q-pl-sm text-subtitle2">{{ formatedDate(request.updatedAt) }}</div>
                    </div>

                </div>
            </div>

            <!-- When the test plan is accepted, start displaying the project number  -->
            <div v-if="tab >= 2" class="row justify-between q-mb-lg">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{ $t("Requests.ProjectNumber") }}</div>
                    <div class="flex items-center text-subtitle2 q-ml-lg" style="height: 58px">{{ tab !== 0 ? request.id : '' }}</div>
                </div>
            </div>
        </div>

        <!-- Transport solution informations -->
        <div v-if="tab < 3 || (tab == 3 && !showTestReport)">
            <Subtitle :title="$t('Compatibility.CarrierSolutions.Title')" />

            <div
                class="bg-grey q-my-md q-mx-sm q-py-xs q-px-md"
                style="border-radius: 50px;">
                <div class="text-capitalize text-bold text-dark text-subtitle1">{{ tab == 0 ? request.transportSolution?.name : request.compatibility.transportSolution?.name || ' ' }}</div>
            </div>
        </div>

        <!-- Medicin informations -->
        <div v-if="tab < 3">
            <Subtitle :title="$t('Compatibility.Drug')" />

            <div class="q-mt-md">
                <div
                    class="bg-grey q-my-xs q-mx-sm q-py-xs q-px-md"
                    style="border-radius: 50px;"
                    v-for="medicin in tab == 0 ? request.medicins : [request.compatibility.medicin]" :key="medicin.id">
                    <div class="text-capitalize text-bold text-dark text-subtitle1">{{ medicin.name + ' - ' + medicin.activeAgent }}</div>
                </div>
            </div>
        </div>

        <!-- Requester Informations OR Prüfplan PDF viewer accordingly to the current tab -->
        <div>
            <div v-if="tab < 2">
                <Subtitle :title="$t('Compatibility.CompatibilityRequest.Wizard.Requester')" />
                <AnfragerInfos :request="tab == 0 ? request : request.compatibilityRequest"/>
            </div>

            <div v-if="tab == 2 || tab == 4">
                <Subtitle :title=" $t('Requests.TestPlanPropositionDocument') " />

                <!-- embed pdf file -->
                <div class="col q-mt-md q-py-md q-px-sm pdf-container bg-grey" style="max-height: 500px; overflow-y: scroll">
                    <VuePdfEmbed :source="pdfUrl" style="cursor: pointer" @click="() => openLink(pdfUrl)" />
                </div>

                <!-- Accept/refuse plan buttons -->
                <div class="row justify-between" v-if="tab == 2 && request.status === 'proposed'">
                    <div class="PurpleToWhite q-px-lg q-mt-lg">
                        <q-btn no-caps flat @click="() => showTextModal = true" style="width: 100%" class="text-bold text-subtitle">
                            Prüfplan Ablehnen
                        </q-btn>
                    </div>
                    <div class="PurpleToWhite q-px-lg q-mt-lg">
                        <q-btn no-caps flat @click="() => {acceptPlan()}" style="width: 100%" class="text-bold text-subtitle">
                            Prüfplan Freigeben
                        </q-btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal to give a reason before rejecting the plan -->
        <TextModal :showModal="showTextModal" :closeModal="() => showTextModal = false" :callback="(text) => refusePlan(text)" />

        <!-- Modal to select medicins for which we want to create a plan -->
        <PlanModal v-if="tab == 0" :medicins="request.medicins" @accept-request="(chosenMeds) => acceptRequest(chosenMeds)" />

        <!-- Test report / Preparat -->
        <div v-if="tab == 3 && !showTestReport">
            <Preparat :editor="true" :request="request" :setShowTestReport="(bool) => setShowTestReport(bool)" />
        </div>
        <TestReport v-if="showTestReport" :request="request" :goBack="() => goBack()" />

        <!-- Fragen ecotox labor -->
        <div v-if="tab >= 1">
            <Subtitle :title=" $t('Requests.Questions') " />
            <RequestFragen :editor="true" :request="request" :goBack="() => goBack()" :currentTab="tab" @updateRequests="() => refreshPage()" />
        </div>

        <!-- Revisions -->
        <div v-if="tab > 0">
            <Subtitle :title=" $t('Requests.Revisions') " class="q-mt-xl"/>
            <div class="q-pa-xs">
                <div class="row q-pa-md q-mt-sm requests-header items-center text-primary">
                    <div class="col-6 ellipsis">{{ $t("Requests.Revision") }}</div>
                    <div class="col-2 ellipsis">{{ $t("Requests.EditedDate") }}</div>
                    <div class="col-2 ellipsis">{{ $t("Compatibility.CompatibilityRequest.Editor") }}</div>
                    <div class="col-2 ellipsis">{{ $t("Requests.Status") }}</div>
                </div>
                <div v-if="request.revisions?.length">
                    <div
                        class="row q-pa-md text-secondary items-start"
                        v-for="(req, index) in request.revisions"
                        v-bind:class="index % 2 !== 0 ? 'even' : ''"
                        :key="req.id">
                        <div class="col-6 ellipsis">{{ index }}</div>
                        <div class="col-2 ellipsis">{{ formatedDate(req.date) }}</div>
                        <div class="col-2 ellipsis">{{ req.author?.username }}</div>
                        <div class="col-2 ellipsis">{{ req.status }}</div>
                    </div>
                </div>
                <div v-else class="no-requests">No revisions yet</div>
            </div>
        </div>

        <!-- Pipeline ending, add date and time to send and close the plan -->
        <div v-if="tab == 4">
            <Subtitle title="Datum + Versionen" />
            <div class="row justify-between q-mb-lg">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">Datum freigabe</div>
                    <q-input filled v-model="date" mask="date" :rules="['date']">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="date">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Close" color="primary" flat></q-btn>
                            </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                    </q-input>
                </div>
                <div class="col q-ml-sm">
                    <div class="text-primary q-ma-sm">Version</div>
                    <div class="bg-grey text-dark flex items-center text-subtitle2 q-ml-sm q-px-md" style="height: 55px">Version {{ request.revisions.length }}</div>
                </div>
            </div>
            <div class="row justify-end">
                <div class="PurpleToWhite">
                    <q-btn no-caps flat @click="() => showValidationModal = true" style="width: 100%" class="text-bold text-h6">
                        Kompatibilität Freigeben
                    </q-btn>
                </div>
            </div>
        </div>

        <!-- Confirm archive modal -->
        <q-dialog v-model="showValidationModal">
            <q-card>
                <q-card-section>
                    <div class="text-h6">{{ $t('Confirmation')}}</div>
                </q-card-section>

                <q-card-section class="q-pt-none column">
                    <div>{{ $t('Requests.ValidatePlan') }}</div>
                    <div>{{ $t('Requests.ThisActionCannotBeUndone') }}</div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat :label="$t('No')" color="white" class="bg-negative" v-close-popup></q-btn>
                    <q-btn outline color="primary" :label="$t('Yes')" @click="() => validatePlan()"></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';

import AnfragerInfos from '../shared/anfrager_infos.vue';
import RequestFragen from '../shared/request_fragen.vue';
import Preparat from '../shared/preparat.vue';
import TestReport from '../shared/test_report.vue';
import PlanModal from './plan_modal.vue';
import TextModal from '../shared/text_modal.vue';

import CompatibilityPlan from '@/model/compatibility-plan';

const Subtitle = {
    props: ['title'],
    render() {
        return (<div>
            <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{this.title}</div>
            <div class="row">
                <div class="col-3 subtitle-thick" />
                <div class="col-9 subtitle-thin" />
            </div>
        </div>);
    }
};

export default defineComponent({
    props: ['request', 'tab', 'goBack'],
    data: function() {
        return {
            editorSelect: '',
            statusSelect: '',
            laborSelect: '',
            date: ref(this.getCurrentDate()),
            showTestReport: ref(false),
            pdfUrl: '',
            requestL: this.request,
            showTextModal: ref(false),
            showValidationModal: ref(false)
        };
    },
    components: {
        Subtitle,
        AnfragerInfos,
        RequestFragen,
        Preparat,
        TestReport,
        PlanModal,
        TextModal,
        VuePdfEmbed
    },
    computed: {
        currentUser() {
            return this.$parent.$parent.$parent.currentUser;
        },
        anfragTitle() {
            return this.tab < 2
                ? this.$t('Compatibility.CompatibilityRequest.Wizard.Request')
                : this.tab === 2
                    ? this.$t('Requests.TestPlan')
                    : 'Kompatibilitätsanfragen';
        }
    },
    beforeMount() {
        if (this.request.testSchedule && this.request.testSchedule.url) {
            this.pdfUrl = process.env.VUE_APP_PUBLIC_URL + this.request.testSchedule.url;
        }
    },
    methods: {
        async refreshPage() {
            await this.$parent.$parent.$parent.retrieveRequests();
        },
        setShowTestReport(bool) {
            this.showTestReport = bool;
        },
        async validatePlan() {
            this.requestL.status = 'validated';
            await this.requestL.save(['status']);
            this.goBack();
        },
        async acceptRequest(chosenMeds) {

            // Change request status
            this.requestL.status = chosenMeds.length ? 'accepted' : 'rejected';
            await this.requestL.save(['status']);

            // Create and save plans for each medicin
            for (let i = 0; i < chosenMeds.length; i++) {
                const plan = CompatibilityPlan.from({
                    status: 'accepted',
                    compatibility: {
                        transportSolution: this.request.transportSolution || '',
                        medicin: chosenMeds[i].medicin
                    },
                    infusionType: this.request.infusionType,
                    reviewerEditor: this.currentUser,
                    conversation: [],
                    revisions: [],
                    compatibilityRequest: this.request
                });
                await plan.save();
            }

            // Go back to the request list
            this.goBack();

        },
        async acceptPlan() {
            this.requestL.status = 'scheduled';
            await this.requestL.save(['status']);
            this.goBack();
        },
        async refusePlan(text) {

            this.request.sendConversation({
                title: 'Rejection',
                content: text + '/url/' + this.pdfUrl,
                date: new Date(),
                author: this.$parent.$parent.$parent.currentUser.id
            });

            this.requestL.status = 'accepted';
            await this.requestL.save(['status']);
            this.showTextModal = false;
            this.goBack();

        },
        formatedDate(date) {
            if (!date) return '';
            const formated = new Date(date).toLocaleDateString(this.$i18n.locale, {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                month: 'long'
            });

            return formated;
        },
        getCurrentDate() {
            const date = new Date();
            return date.getFullYear() + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getDate()).padStart(2, '0');
        },
        openLink(link) {
            window.open(link, '_blank');
        }
    }
});
</script>

<style lang="sass">
@import '../../../styles/quasar.variables.scss'

.viewer-back-btn
  background: $secondary
  width: 25px
  height: 25px

.btn-click:active
    background: $grey

.primary-underline
    padding-bottom: 10px
    border-bottom: 2px solid $primary

.subtitle-thick
    border-bottom: 5px solid $primary

.subtitle-thin
    border-bottom: 3px solid $primary

</style>
