import i18n from '@/i18n';
import { authReady, currentJwt } from '@/utils/auth';
import { Ref, ref, watch } from 'vue';
import { compatibilityFrom, ICompatibilityData } from './helper/interface';
import { extractLocalizedId } from './helper/locale';

export class MutipleHandelsnamesButOneAgent {
    agent = '';
    handelsnames: string[] = [];
}

export class Compatibility {
    mutipleHandelsnamesButOneAgent: MutipleHandelsnamesButOneAgent[] = [];
    sameAgent = 'none';
    referenceWhenSameAgent = true;
    selected = false;

    id: string = '0';
    calculation: ICompatibilityData;

    constructor(
        id: number | string,
        calculation: ICompatibilityData
    ) {
        this.id = String(id);
        this.calculation = calculation;
    }


    static tab: Ref<Compatibility[]> = ref([]);

    static coinfused: Ref<Compatibility[]> = ref([]);

    static containered: Ref<Compatibility[]> = ref([]);

    static async init(transport?: any): Promise<Ref<Compatibility[]>> {
        await authReady;
        let data: any[] = [];
        let page: number = 1;
        let pageCount = 1000;
        do {
            const res = await fetch(process.env.VUE_APP_API_URL + '/compatibilities?populate[calculation][populate]=*' + (transport ? ('&filters[calculation][transportSolution][name][$eq]=' + transport.name) : '') + '&pagination[pageSize]=100&pagination[page]=' + page + '&locale=' + i18n.global.locale.value, {
                headers: {
                    Authorization: 'Bearer ' + currentJwt.value
                }
            });
            const pageData = (await res.json());
            pageCount = pageData.meta.pagination.pageCount;
            data = [...data, ...pageData.data];
            page++;
        } while (pageCount >= page);

        this.tab.value = [];
        this.coinfused.value = [];
        this.containered.value = [];

        for (let i = 0; i < data.length; i++) {

            const c = new Compatibility(extractLocalizedId(data[i]), compatibilityFrom(data[i].attributes.calculation));
            if (i > 0) {
                for (let j = 0; j < i; j++) {
                    if (this.tab.value[j].calculation.medicin.activeAgent === c.calculation.medicin.activeAgent) {
                        this.tab.value[j].sameAgent = c.calculation.medicin.activeAgent;
                        c.referenceWhenSameAgent = false;
                        c.sameAgent = c.calculation.medicin.activeAgent;

                    }
                }
            }
            this.tab.value.push(c);
            if (c.calculation.infusionType === 'coinfusion') this.coinfused.value.push(c);
            if (c.calculation.infusionType === 'container') this.containered.value.push(c);
        }

        return (transport === 'coinfusion') ? this.coinfused : (transport === 'container') ? this.containered : this.tab;
    }


}
watch(i18n.global.locale, () => {
    if (Compatibility.tab.value.length > 0) Compatibility.init();
});


export default Compatibility;
