
import { defineComponent, Ref, ref } from 'vue';
import { searchInText } from '@/utils/search';

import GreenTitle from '@/components/shared/green_title.vue';
import WizardTab from '../shared/wizard_tab.vue';
import WizardTable from './prufer_wizard_table.vue';
import RequestViewer from './prufer_request_viewer.vue';

import CompatibilityPlan from '@/model/compatibility-plan';

interface dataI {
    currentTab: Ref<number>,
    editing: Ref<string|null>,
    searchFilter: Ref<string>
};
export default defineComponent({
    props: ['requests', 'updateRequests', 'currentUser'],
    setup(): dataI {
        console.log('load prufen wizard');
        return {
            currentTab: ref(0),
            editing: ref(null),
            searchFilter: ref('')
        };
    },
    components: {
        WizardTab,
        WizardTable,
        GreenTitle,
        RequestViewer
    },
    computed: {
        tabRequests(): CompatibilityPlan[] {
            return this.requests.filter((request: CompatibilityPlan) => ['accepted', 'ready', 'proposed', 'scheduled', 'completed'].includes(request.status));
        },
        filteredTabRequests(): CompatibilityPlan[] {
            const filtered = this.tabRequests.filter((request: CompatibilityPlan) => searchInText(request.compatibility.medicin.name, this.searchFilter));

            if (filtered.length > 0) {
                return filtered;
            } else {
                return this.tabRequests;
            }
        }
    },
    methods: {
        async refreshPage() {
            await this.updateRequests();
        },
        changeTab(n: number) {
            this.currentTab = n;
        },
        openRequest(req: CompatibilityPlan | null) {
            if (Number(this.currentTab) === 0 && req) {
                if (!req.reviewerLaboratory) {
                    req.reviewerLaboratory = this.currentUser;
                    req.save(['reviewerLaboratory']);
                }
            }
            if (req && req.id) {
                this.editing = req.id;
            } else {
                this.editing = null;
            }
        }
    }
});
