import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98e44bc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-mt-xl" }
const _hoisted_2 = { class: "col q-pb-md" }
const _hoisted_3 = { class: "col-1 text-subtitle1 text-bold text-grey-8" }
const _hoisted_4 = { class: "col text-primary" }
const _hoisted_5 = { class: "q-my-lg" }
const _hoisted_6 = { class: "text-bold" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_MainContent = _resolveComponent("MainContent")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MainContent, { class: "" }, {
      default: _withCtx(() => [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.processedDate(_ctx.currentNews?.date)), 1),
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.currentNews?.title), 1)
        ]),
        _createElementVNode("article", _hoisted_5, [
          _createElementVNode("section", _hoisted_6, _toDisplayString(_ctx.currentNews?.description), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentNews?.blocks, (block, idx) => {
          return (_openBlock(), _createElementBlock("article", {
            class: _normalizeClass('gt-sm news-block '+block.component),
            key: idx
          }, [
            (block.component !== 'text-bloc')
              ? (_openBlock(), _createElementBlock("aside", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.show(_ctx.baseApi + (block.image?.largestUrl || '')))
                }, [
                  _createVNode(_component_q_img, {
                    src: _ctx.baseApi + (block.image?.largestUrl || ''),
                    fit: "cover"
                  }, null, 8, ["src"])
                ], 8, _hoisted_7))
              : _createCommentVNode("", true),
            (block.component === 'text-bloc')
              ? (_openBlock(), _createElementBlock("section", {
                  key: 1,
                  class: "",
                  innerHTML: _ctx.compileMarkDown(block.text || '')
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentNews?.blocks, (block, idx) => {
          return (_openBlock(), _createElementBlock("article", {
            class: _normalizeClass('lt-md news-block'),
            key: idx
          }, [
            (block.component !== 'text-bloc')
              ? (_openBlock(), _createElementBlock("aside", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.show(_ctx.baseApi + (block.image?.largestUrl || '')))
                }, [
                  _createVNode(_component_q_img, {
                    src: _ctx.baseApi + (block.image?.largestUrl || ''),
                    fit: "cover"
                  }, null, 8, ["src"])
                ], 8, _hoisted_9))
              : _createCommentVNode("", true),
            (block.component === 'text-bloc')
              ? (_openBlock(), _createElementBlock("section", {
                  key: 1,
                  class: "",
                  innerHTML: _ctx.compileMarkDown(block.text || '')
                }, null, 8, _hoisted_10))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_vue_easy_lightbox, {
      visible: _ctx.visible,
      imgs: _ctx.currentImage,
      index: 0,
      onHide: _ctx.handleHide
    }, null, 8, ["visible", "imgs", "onHide"])
  ]))
}