
import { defineComponent, Ref, ref } from 'vue';
import Compatibility from '@/model/compatibilities';

// Import Wizard from '@/components/kompat/wizard_compat.vue';
import MainContent from '@/components/layout/main_content.vue';

import KompatIntro from './kompat_intro.vue';
import Kompat1 from './kompat_1.vue';
import Kompat2 from './kompat_2.vue';
import Kompat3 from './kompat_3.vue';
import Kompat4 from './kompat_4.vue';
import Kompat5 from './kompat_5.vue';
import TransportSolution from '@/model/transports';

import TabViewer from '@/components/osmo/TabViewer.vue';

import IWizardImage from '@/model/helper/wizardImage';

interface dataI {
  prev: Ref<string>;
  next: Ref<string>;
  componentName: Ref<string>;
  page2: Ref<string>;
  tabOfViewName: Ref<string[]>;
  currentPage: Ref<number>;
  transport: Ref<TransportSolution|null>;
  tabOfSelectedMeds: Ref<Compatibility[]>;
  perfusionWay: Ref<string>;
  behalOrLeit: Ref<string>;
  count: Ref<number>
  previousExpandedMedId: Ref<number>;
  hideElements: Ref<boolean>;
  wizardImages: Ref<IWizardImage[]>;
  showNoneSelectedError: Ref<boolean>;
}

export default defineComponent({
    components: {
        MainContent,
        KompatIntro,
        Kompat1,
        Kompat2,
        Kompat3,
        Kompat4,
        Kompat5,
        TabViewer
    },
    setup(): dataI {
        return {
            prev: ref(''),
            next: ref(''),
            componentName: ref('KompatIntro'),
            page2: ref(''),
            tabOfViewName: ref([
                'KompatIntro',
                'Kompat1',
                'Kompat2',
                'Kompat3',
                'Kompat4',
                'Kompat5'
            ]),
            currentPage: ref(0),
            transport: ref(new TransportSolution(0, '', '', false, false, '') as TransportSolution),
            tabOfSelectedMeds: ref([]),
            perfusionWay: ref(''),
            behalOrLeit: ref(''),
            count: ref(0),
            previousExpandedMedId: ref(-1),
            hideElements: ref(false),
            wizardImages: ref([
                { src: 'step_1.png', alt: 'step_1_done.png' },
                { src: 'step_4.png', alt: 'step_4_done.png' },
                { src: 'step_2.png', alt: 'step_2_done.png' },
                { src: 'step_3.png', alt: 'step_3_done.png' }
            ]),
            showNoneSelectedError: ref(false)
        };
    },
    methods: {
        changePageWithWizard(n:number) {
            /*
             * Si la page a la quelle je veux aller est inferieur
             * page actuelle = page selectionnee
             */
            if (this.currentPage > n) {
                this.currentPage = n;
                this.componentName = this.tabOfViewName[this.currentPage];
            }
            this.setHideElements(false);
        },
        updateQuantity(n: number, s: string) {

        },
        meds_expansion(n: number) {
            if (this.tabOfSelectedMeds[n].selected === false) {
                this.tabOfSelectedMeds[n].selected = true;
            } else {
                this.tabOfSelectedMeds[n].selected = false;
            }
        },
        updateSelectedMeds(s: Compatibility[]) {
            this.tabOfSelectedMeds = s;
        },
        updateTransport(e: TransportSolution) {
            this.transport = e;
        },

        updatePage(e: string) {
            this.componentName = e;
            this.currentPage++;
        },

        updatePerfusionWay(s: string) {
            this.perfusionWay = s;
            this.tabOfSelectedMeds = [];
        },

        prevPage() {
            this.componentName = this.tabOfViewName[this.currentPage - 1];
            this.currentPage--;
        },

        nextPage() {
            if (this.currentPage === 3 && this.tabOfSelectedMeds.length === 0) {
                this.showNoneSelectedError = true;
                return;
            }
            this.componentName = this.tabOfViewName[this.currentPage + 1];
            this.currentPage++;
        },
        printPage() {
            window.print();
        },
        setHideElements(b: boolean) {
            this.hideElements = b;
            return b;
        }
    }
});
