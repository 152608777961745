
import { ref, Ref, defineComponent } from 'vue';
import { TransportSolution } from '@/model/transports';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import Card from '@/components/shared/card.vue';

interface dataI {
  transportSolutionTab: Ref<TransportSolution[]>;
}

export default defineComponent({
    components: {
        Card
    },
    props: ['changeTransport', 'changePage', 'tabOfViewName', 'currentPage'],
    setup(): dataI {
        return {
            transportSolutionTab: ref([])
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.CarrierSolutions.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }
        },
        displayDescription(description: string) {
            if (window.innerWidth > 600) {
                return description;
            } else {
                return '';
            }
        }
    },

    async mounted() {
        this.updateSubNavBar();
        const data = await TransportSolution.init();
        const json = data.value;

        this.transportSolutionTab = json;

        // Sort the transport solutions by order value
        this.transportSolutionTab.sort((a, b) => {
            return a.order - b.order;
        });
    },

    computed: {
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    }
});
