
import BbHeader from './components/layout/header/bb-header.vue';
import BbFooter from './components/layout/footer/bb-footer.vue';
import { defineComponent, Ref, ref } from 'vue';

interface dataI {
  title: Ref<String>;
  subtitle: Ref<String>;
  breadCrumbsTab: Ref<String[]>;
  breadCrumbsPaths: Ref<String[]>;
}

export default defineComponent({
    name: 'App',
    components: {
        BbHeader,
        BbFooter
    },

    setup(): dataI {
        return {
            title: ref('Default Title'),
            subtitle: ref('Default Subtitle'),
            breadCrumbsTab: ref(['item1', 'item2', 'item3', 'item4']),
            breadCrumbsPaths: ref([''])
        };
    },
    methods: {
        updateTitles(t:string, st:string) {
            document.title = 'BBRAUN - ' + this.$t(t);
            this.title = t;
            this.subtitle = st;
        }
    }

});
