import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "q-pa-xs" }
const _hoisted_2 = { class: "row q-pa-md q-mt-sm requests-header items-center text-primary" }
const _hoisted_3 = { class: "col-4 ellipsis" }
const _hoisted_4 = { class: "col-3 ellipsis" }
const _hoisted_5 = { class: "col-2 ellipsis" }
const _hoisted_6 = { class: "col-3 ellipsis" }
const _hoisted_7 = {
  key: 0,
  class: "no-requests"
}
const _hoisted_8 = {
  key: 0,
  class: "other-tab-subtitle"
}
const _hoisted_9 = { class: "col-4 ellipsis" }
const _hoisted_10 = { class: "col-3 ellipsis" }
const _hoisted_11 = { class: "col-2 ellipsis" }
const _hoisted_12 = { class: "col-2 ellipsis" }
const _hoisted_13 = { class: "col-1 ellipsis row justify-end" }
const _hoisted_14 = { class: "line-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.Wizard.Request')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.LabTester')), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Requests.Status")), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.Wizard.Date')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.currentTabRequests, _ctx.otherTabRequests], (requestTables, tableIndex) => {
      return (_openBlock(), _createElementBlock("div", { key: tableIndex }, [
        (_ctx.currentTabRequests.length === 0 && tableIndex === 0 && !_ctx.searching)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("Requests.NoRequestAtThisStep")), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(requestTables, (req, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            (tableIndex === 1 && _ctx.otherTabRequests.length > 0 && index === 0 && !_ctx.searching)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("Requests.OtherTabsRequests")), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["row q-pa-md text-secondary items-center", (index % 2 === 0 ? 'even' : '') + (tableIndex === 0 || _ctx.searching ? '' : ' not-current-tab')])
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(req.compatibility.medicin.name), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(req.compatibilityRequest?.firstName + ' ' + req.compatibilityRequest?.lastName), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t(`Status.${req.status}`)), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formatedDate(req.createdAt)), 1),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    flat: "",
                    style: {"width":"100%","height":"100%"},
                    onClick: () => _ctx.openRequest(req)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        class: "text-grey",
                        name: "arrow_forward_ios"
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ])
            ], 2)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}