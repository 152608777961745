
import MainContent from '@/components/layout/main_content.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

import { defineComponent, ref, Ref } from 'vue';
import { searchInText } from '@/utils/search';

import Product from '@/model/product';
import { News } from '@/model/news';
import FaqCategory from '@/model/faq_categorie';

import { marked } from 'marked';
import { baseUrl } from 'marked-base-url';

interface IItem {
    thumbnail: string|undefined;
    title: string|undefined;
    description: string|undefined;
    url: string|undefined;
    type: string;
    coef?: number;
}

interface dataI {
    products: Ref<Product[]>;
    news: Ref<News[]>;
    faq: Ref<FaqCategory[]>;
    searchQuery: Ref<string>;
    selectedRadio: Ref<string>;
}
export default defineComponent({
    components: {
        MainContent
    },
    setup(): dataI {
        return {
            products: ref([]),
            searchQuery: ref(''),
            news: ref([]),
            faq: ref([]),
            selectedRadio: ref('all')
        };
    },
    computed: {
        headerTitle(): string {
            return this.$t('Login.LoginSection.ResetPassword');
        },
        items(): IItem[] {
            const searchQuery = this.searchQuery.toLowerCase();
            const products = this.products;
            const news = this.news;
            const faq = this.faq;

            const items: IItem[] = [];

            if (this.selectedRadio === 'all' || this.selectedRadio === 'products') {
                products.forEach((p: Product) => {
                    const productText = [p.name, p.description, p.dosage];
                    Array.prototype.push.apply(productText, p.informations.map(item => item.content));
                    Array.prototype.push.apply(productText, p.faqs.map(item => item.question + ' ' + item.answer));

                    const inProduct = searchInText(searchQuery, productText.join(' '));
                    if (inProduct !== false) {
                        items.push({
                            thumbnail: p.cover?.url,
                            title: p?.name,
                            description: p.description,
                            url: '/product/' + p.id,
                            type: 'product',
                            coef: Number(inProduct)
                        });
                    }
                });
            }

            if (this.selectedRadio === 'all' || this.selectedRadio === 'news') {
                news.forEach(n => {
                    const newsText = [n.title, n.description];
                    Array.prototype.push.apply(newsText, n.blocks.map(item => item.text || ''));
                    const inNews = searchInText(searchQuery, newsText.join(' '));
                    if (inNews !== false) {
                        items.push({
                            thumbnail: n.thumbnail,
                            title: n.title,
                            description: n.description,
                            url: '/news/' + n.id,
                            type: 'news',
                            coef: Number(inNews)
                        });
                    }
                });
            }

            if (this.selectedRadio === 'all' || this.selectedRadio === 'faq') {
                faq.forEach(f => {
                    f.sections.forEach(element => {
                        const faqText = [f.title, element.description, element.title];
                        const inFaq = searchInText(searchQuery, faqText.join(' '));
                        if (inFaq !== false) {
                            items.push({
                                thumbnail: '',
                                title: f.title + ' - ' + element.title,
                                description: element.description,
                                url: '/faq/?category=' + encodeURIComponent(f.title),
                                type: 'faq',
                                coef: Number(inFaq)
                            });
                        }

                    });

                });
            }
            items.sort((itA, itB) => {
                if (!itA.coef || !itB.coef) {
                    return 0;
                } else {
                    return itA.coef - itB.coef;
                }
            });
            return items;
        },
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Search', path: 'search' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Search', 'SearchResults');
            }
        },
        getThumbnailUrl(thumbnail: string): string {
            return this.baseApi + thumbnail;
        },
        compileMarkDown(text: string): string {
            return marked.parse(text);
        }
    },
    mounted() {
        marked.use(baseUrl(this.baseApi || ''));

        this.updateSubNavBar();
    },
    async beforeMount() {
        this.products = (await Product.init()).value;
        this.news = (await News.init()).value;
        this.faq = (await FaqCategory.init()).value;
        this.searchQuery = String(this.$route.query.search);
    },
    watch: {
        '$route.query.search': function(newValue: string) {
            this.searchQuery = newValue;
        }
    }
});
