import { currentJwt, authReady } from '@/utils/auth';
import { ImageMedia } from './helper/image';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { extractLocalizedId } from './helper/locale';
import { FaqCategory } from './faq_categorie';
import { fromDataType } from './helper/interface';

interface IProductInfo {
    title: string,
    content: string
}

interface IDocument {
    name: string,
    document: any
}
interface IArticle {
    description: string,
    reference: string,
    pharmacode: string,
    quantity: number
}
interface IProductFAQ {
    question: string,
    answer: string
}


export interface IProductCategory {
    name: string,
    products: any[]
}
export interface IProductOptions {
    id: number | string;
    name?: string;
    description?: string;
    dosage?: string;
    cover?: ImageMedia;
    informations?: IProductInfo[];
    faqs?: IProductFAQ[];
    documents?: IDocument[];
    faqsCat?: FaqCategory[];
    articles?: IArticle[];
    quantityUnit?: string;
    categoryRef?: string;
}

export interface IBrochure {
    name: string,
    file: any,
    cover: ImageMedia
}

export class Product {

    id: string = '0';
    name: string = '';
    description: string = '';
    dosage: string = '';
    cover?: ImageMedia;
    informations: IProductInfo[] = [];
    faqsCategories: FaqCategory[] = [];
    faqs: IProductFAQ[] = [];
    documents: IDocument[] = [];
    articles: IArticle[] = [];
    quantityUnit: string = '';
    categoryRef: string = '';

    constructor(
        {
            id,
            name,
            description,
            dosage,
            cover,
            informations,
            faqs,
            documents,
            faqsCat,
            articles,
            quantityUnit,
            categoryRef
        }: IProductOptions
    ) {
        this.id = String(id);
        this.name = name ?? this.name;
        this.description = description ?? this.description;
        this.dosage = dosage ?? this.dosage;
        if (typeof cover !== 'undefined') this.cover = cover;
        this.informations = informations ?? this.informations;
        this.faqs = faqs ?? this.faqs;
        this.documents = documents ?? this.documents;
        this.faqsCategories = faqsCat ?? this.faqsCategories;
        this.articles = articles ?? this.articles;
        this.quantityUnit = quantityUnit ?? this.quantityUnit;
        this.categoryRef = categoryRef ?? this.categoryRef;
    }

    static products : Ref<Product[]> = ref([]);
    static productCategories : Ref<{[id: string]: IProductCategory}> = ref({});

    static async init() :Promise<Ref<Product[]>> {
        await authReady;
        let data: any[] = [];
        let page: number = 1;
        let pageCount = 1000;
        do {
            const res = await fetch(process.env.VUE_APP_API_URL + '/products?' +
            'populate[productCategory][populate]=1' +
            '&populate[cover][populate]=*' +
            '&populate[information][populate]=*' +
            '&populate[localizations][populate]=*' +
            '&populate[document][populate]=*' +
            '&populate[articles][populate]=*' +
            '&populate[generalFAQ][populate]=1' +
            '&populate[FAQ][populate]=*' +
            '&pagination[pageSize]=100&pagination[page]=' + page +
            '&locale=' + i18n.global.locale.value, {
                headers: {
                    Authorization: 'Bearer ' + currentJwt.value
                }
            });
            const pageData = (await res.json());
            pageCount = pageData.meta.pagination.pageCount;
            data = [...data, ...pageData.data];
            page++;
        } while (pageCount >= page);


        this.products.value.splice(0);

        // A this.productCategories.value = {};
        for (const k in this.productCategories.value) {
            delete this.productCategories.value[k];
        }

        // A- new ImageMedia(data[i].cover),

        for (let i = 0; i < data.length; i++) {
            const productOptions: IProductOptions = {
                id: extractLocalizedId(data[i]),
                name: data[i].attributes.name,
                description: data[i].attributes.description,
                dosage: data[i].attributes.dosage,
                informations: data[i].attributes.information,
                faqs: data[i].attributes.FAQ,
                documents: data[i].attributes.documents,
                faqsCat: data[i].attributes.generalFAQ.data?.map((item: any) => new FaqCategory({ ...fromDataType(item), id: extractLocalizedId(item) })),
                articles: data[i].attributes.articles,
                quantityUnit: data[i].attributes.QuantityUnit,
                categoryRef: data[i].attributes.productCategory ? data[i].attributes.productCategory.data?.id : null
            };
            if (data[i].attributes.cover?.data?.id) productOptions.cover = new ImageMedia({ ...data[i].attributes.cover.data.attributes, id: data[i].attributes.cover.data.id });
            const c = new Product(productOptions);

            this.products.value.push(c);

            if (data[i].attributes.productCategory.data) {
                if (!this.productCategories.value[String(c.categoryRef)]) {
                    this.productCategories.value[String(c.categoryRef)] = { name: data[i].attributes.productCategory.data.attributes.name, products: [] };
                }
                this.productCategories.value[String(c.categoryRef)].products.push(c);
            }

        }

        return this.products;
    }

    static async getBrochures() :Promise<any> {
        const localizedBrochures: any = {};
        const res = await fetch(process.env.VUE_APP_API_URL + '/brochure?populate[localizations][populate][brochure][populate][file]=*&populate[localizations][populate][brochure][populate][cover]=*&populate[brochure][populate][file]=*&populate[brochure][populate][cover]=*', {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });
        const data = (await res.json()).data;

        if (!data?.attributes?.brochure) return [];

        for (const item of data.attributes.localizations.data) {
            localizedBrochures[item.attributes.locale] = item.attributes.brochure.map((item: any) => {
                return {
                    name: item.name || '',
                    file: item.file?.data?.attributes ? new ImageMedia({ ...item.file.data.attributes, id: item.file.data.id }) : null,
                    cover: item.cover?.data?.attributes ? new ImageMedia({ ...item.cover.data.attributes, id: item.cover.data.id }) : null
                };
            });
        };
        localizedBrochures[data.attributes.locale] = data.attributes.brochure.map((item: any) => {
            return {
                name: item.name || '',
                file: item.file?.data?.attributes ? new ImageMedia({ ...item.file.data.attributes, id: item.file.data.id }) : null,
                cover: item.cover?.data?.attributes ? new ImageMedia({ ...item.cover.data.attributes, id: item.cover.data.id }) : null
            };
        });

        return localizedBrochures;
    }

}
watch(i18n.global.locale, () => {
    if (Product.products.value.length > 0) Product.init();
});

export default Product;
