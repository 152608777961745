
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['title', 'desc', 'path', 'selectable', 'solution', 'url', 'notification', 'isProduct'],
    data() {
        return {
            image: this.url
        };
    },
    methods: {
        init(url: any) {
            switch (url) {
                case 'osmo':
                    this.image = require('@/assets/osmo.jpeg');
                    return;
                case 'product':
                    this.image = require('@/assets/product.jpeg');
                    return;
                case 'faq':
                    this.image = require('@/assets/faq.jpeg');
                    return;
                case 'transport':
                    this.image = require('@/assets/transport.jpeg');
                    return;
                default:
                    console.debug('Displaying url', url);
                    this.image = url;
            }
        }
    },
    mounted: function() {
        this.init(this.url);
    },
    computed: {
        isExternalUrl() {
            return (url: string) => {
                return url.startsWith('http');
            };
        }
    }
});
