
import { defineComponent, Ref, ref } from 'vue';
import Med from '@/model/med';
import Compatibility from '@/model/compatibilities';
import CreateRequest from './createRequest.vue';

interface dataI {
  mutipleHandelsnamesButOneWirkstoffTab: Ref<Compatibility[]>;
  perf: Ref<string | null>;
  tabOfMed: Ref<Med[]>;
  filterVar: Ref<string>;
  ABC: Ref<string[]>;
  ABCBySection: Ref<string[]>;
  choosenMeds: Ref<Compatibility[]>;
  medOrMol: Ref<string>;
  compatibilityTab: Ref<Compatibility[]>;
  showRequestForm: Ref<boolean>;
}

export default defineComponent({
    props: ['tabOfSelectedMeds', 'updateSelectedMeds', 'transport', 'perfusionWay'],
    setup(): dataI {
        return {
            mutipleHandelsnamesButOneWirkstoffTab: ref([]),
            perf: ref(localStorage.getItem('Perf')),
            tabOfMed: ref([]),
            filterVar: ref(''),
            ABC: ref([
                'All',
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z',
                '0-9'
            ]),
            ABCBySection: ref([
                'All',
                'A-D',
                'E-H',
                'I-M',
                'N-Q',
                'R-T',
                'U-W',
                'X-9'
            ]),
            choosenMeds: ref([]),
            medOrMol: ref('med'),
            compatibilityTab: ref([]),
            showRequestForm: ref(false)
        };
    },
    components: {
        CreateRequest
    },
    methods: {
        ExpansionABC(s: string): string[] {
            const tab: string[] = [];
            for (let i = 0; i < this.ABC.length; i++) {
                if (s.startsWith(this.ABC[i])) {
                    tab.push(this.ABC[i]);
                    tab.push(this.ABC[i + 1]);
                    tab.push(this.ABC[i + 2]);
                    tab.push(this.ABC[i + 3]);
                    i = this.ABC.length;
                }
            }
            return tab;
        },
        medOrMolSwitcher() {
            if (this.medOrMol === 'med') {
                this.medOrMol = 'mol';
            } else {
                this.medOrMol = 'med';
            }
        },
        filter(f: string) {
            if (f === 'All') {
                this.filterVar = '';
            } else {
                this.filterVar = f;
            }
        },
        filterBySection(s: string) {
            if (s === 'All') {
                this.filterVar = '';
            } else {
                this.filterVar = s;
            }
        },
        addMedsByMol: function(s: Compatibility) {
            if (s.sameAgent !== 'none') {
                for (let i = 0; i < this.compatibilityTab.length; i++) {
                    let c = 0;
                    if (this.compatibilityTab[i].sameAgent === s.sameAgent) {
                        for (let j = 0; j < this.choosenMeds.length; j++) {
                            if (this.choosenMeds[j].id === this.compatibilityTab[i].id) {
                                c = 1;
                            }
                        }
                        if (c === 0) {
                            this.choosenMeds.push(this.compatibilityTab[i]);
                        }
                    }
                }
            } else {
                let c = 0;
                for (let j = 0; j < this.choosenMeds.length; j++) {
                    if (this.choosenMeds[j].id === s.id) {
                        c = 1;
                    }
                }
                if (c === 0) {
                    this.choosenMeds.push(s);
                }
            }

            this.updateSelectedMeds(this.choosenMeds);
        },

        addMedsByMed: function(s: Compatibility) {
            let c = 0;
            for (let i = 0; i < this.choosenMeds.length; i++) {
                if (this.choosenMeds[i].id === s.id) {
                    c = 1;
                }
            }
            if (c === 0) {
                for (let i = 0; i < this.compatibilityTab.length; i++) {
                    const element = this.compatibilityTab[i];

                    if (element.calculation.medicin.name === s.calculation.medicin.name) {
                        this.choosenMeds.push(element);
                    }
                }
            }
            this.updateSelectedMeds(this.choosenMeds);
        },
        removeMedsByMol: function(s: Compatibility) {
            for (let i = 0; i < this.choosenMeds.length; i++) {
                if (s.sameAgent !== 'none') {
                    if (this.choosenMeds[i].sameAgent === s.sameAgent) {
                        this.choosenMeds.splice(i, 1);
                        i--;
                    }
                } else {
                    if (this.choosenMeds[i].id === s.id) {
                        this.choosenMeds.splice(i, 1);
                        i = this.choosenMeds.length;
                    }
                }
            }
            this.updateSelectedMeds(this.choosenMeds);
        },
        removeMedsByMed: function(s: Compatibility) {
            for (let i = 0; i < this.choosenMeds.length; i++) {
                if (this.choosenMeds[i].calculation.medicin.name === s.calculation.medicin.name) {
                    this.choosenMeds.splice(i, 1);
                    i -= 1;
                }
            }
            this.updateSelectedMeds(this.choosenMeds);
        },
        choosePerf(pChoice: string) {
            localStorage.setItem('Perf', pChoice);
        },
        toggleRequestForm() {
            this.showRequestForm = !this.showRequestForm;
        },
        selected(med: Compatibility) {
            if (this.tabOfSelectedMeds.map((m: Compatibility) => m.id).includes(med.id) ||
              (this.medOrMol === 'mol' && this.tabOfSelectedMeds.map((m: Compatibility) => m.calculation.medicin.activeAgent).includes(med.calculation.medicin.activeAgent))) {
                return true;
            } else {
                return false;
            }
        }
    },

    async beforeCreate() {
        const res = await Compatibility.init(this.transport);

        this.compatibilityTab = res.value.filter(
            (c: Compatibility) => c.calculation.infusionType === this.perfusionWay
        );
        this.choosenMeds = this.tabOfSelectedMeds;
    },
    computed: {
        filteredTab(): Compatibility[] {
            const filteredCompatibilityTab: Compatibility[] = [];

            if (this.compatibilityTab.length === 0) {
                return filteredCompatibilityTab;
            }

            // Filter array by letter by making name/activeAgent lowerCase and normalized before comparing
            if (this.medOrMol === 'med') {
                for (let i = 0; i < this.compatibilityTab.length; i++) {
                    if (
                        this.filterVar === '' ||
                        this.compatibilityTab[i].calculation.medicin.name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()[0].match(new RegExp('[' + this.filterVar.toLowerCase() + ']', 'g')) !== null
                    ) {
                        filteredCompatibilityTab.push(this.compatibilityTab[i]);
                    }
                }
            }
            if (this.medOrMol === 'mol') {
                for (let i = 0; i < this.compatibilityTab.length; i++) {
                    if (
                        this.filterVar === '' ||
                        (
                            this.compatibilityTab[i].calculation.medicin.activeAgent.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()[0].match(new RegExp('[' + this.filterVar.toLowerCase() + ']', 'g')) !== null
                        )
                    ) {
                        filteredCompatibilityTab.push(this.compatibilityTab[i]);
                    }
                }
            }

            if (filteredCompatibilityTab.length === 0) {
                return filteredCompatibilityTab;
            }

            // Sort array by name/activeAgent
            const sortedArray: Compatibility[] = this.medOrMol === 'med'
                ? filteredCompatibilityTab.sort((a, b) => {
                    const normA = a.calculation.medicin.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
                    const normB = b.calculation.medicin.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');

                    return normA.localeCompare(normB);
                })
                : filteredCompatibilityTab.sort((a, b) => {
                    const normA = a.calculation.medicin.activeAgent.normalize('NFD').replace(/\p{Diacritic}/gu, '');
                    const normB = b.calculation.medicin.activeAgent.normalize('NFD').replace(/\p{Diacritic}/gu, '');

                    return normA.localeCompare(normB);
                });


            // Remove duplicates
            const result: Compatibility[] = [];
            for (let i = 0; i < sortedArray.length - 1; i++) {
                if ((this.medOrMol === 'med' && sortedArray[i].calculation.medicin.name !== sortedArray[i + 1].calculation.medicin.name) ||
                    (this.medOrMol === 'mol' && sortedArray[i].calculation.medicin.activeAgent !== sortedArray[i + 1].calculation.medicin.activeAgent)) {
                    result.push(sortedArray[i]);
                }
            }
            result.push(sortedArray[sortedArray.length - 1]);

            return result;
        },
        tabOfSelectedMol(): Compatibility[] {
            let filteredTab: Compatibility[] = [];
            filteredTab = this.choosenMeds.slice();

            return filteredTab;
        },
        uniqueMol(): Compatibility[] {
            let filteredTab: Compatibility[] = [];
            filteredTab = this.tabOfSelectedMol.slice();

            filteredTab = filteredTab.reduce((acc: Compatibility[], current: Compatibility) => {
                const molsName = acc.map(med => med.calculation.medicin.activeAgent);
                if (molsName.indexOf(current.calculation.medicin.activeAgent) === -1) {
                    acc.push(current);
                }
                return acc;
            }, []);

            return filteredTab;
        },
        uniqueMed(): Compatibility[] {
            let filteredTab: Compatibility[] = [];
            filteredTab = this.tabOfSelectedMeds.slice();

            filteredTab = filteredTab.reduce((acc: Compatibility[], current: Compatibility) => {
                const medsName = acc.map(med => med.calculation.medicin.name);
                if (medsName.indexOf(current.calculation.medicin.name) === -1) {
                    acc.push(current);
                }
                return acc;
            }, []);

            return filteredTab;
        }
    }
});
