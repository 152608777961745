import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73182ea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "column",
  style: {"gap":"8px"}
}
const _hoisted_2 = {
  key: 0,
  registered: "",
  class: "bg-negative q-pa-md text-white error-msg"
}
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col no-padding bg-grey" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col no-padding bg-grey q-mb-xl" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col no-padding bg-grey" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col no-padding bg-grey" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col no-padding bg-grey" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col no-padding bg-grey" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "col no-padding bg-grey" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "col no-padding bg-grey q-mb-xl" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "col no-padding bg-grey" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "col no-padding bg-grey" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "col no-padding bg-grey" }
const _hoisted_26 = { class: "row items-start q-mt-md" }
const _hoisted_27 = { class: "col q-mt-xs" }
const _hoisted_28 = {
  href: "/privacy",
  target: "_blank"
}
const _hoisted_29 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.FirstName')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_input, {
          dense: "",
          borderless: "",
          modelValue: _ctx.firstName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
          debounce: "500",
          color: "green",
          class: "col q-px-sm"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.LastName')), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.SpitalInstitution')), 1),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.institution,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.institution) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.PositionOrFunction')), 1),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.position,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.position) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.Street')), 1),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.street,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.street) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.PLZ')), 1),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.postalCode,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.postalCode) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.Location')), 1),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.city,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.city) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.Country')), 1),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.country,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.country) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('Compatibility.CompatibilityRequest.EmailAddress')), 1),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.email) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-px-sm",
        type: "email"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('Login.LoginSection.Password')), 1),
    _createElementVNode("div", _hoisted_23, [
      _createVNode(_component_q_input, {
        class: "col q-px-sm",
        dense: "",
        borderless: "",
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.password) = $event)),
        debounce: "500",
        color: "green",
        type: !_ctx.showPassword ? 'password' : 'text'
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: !_ctx.showPassword ? 'visibility_off' : 'visibility',
            class: "cursor-pointer",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["modelValue", "type"])
    ]),
    _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('Login.LoginSection.RepeatPassword')), 1),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(_component_q_input, {
        class: "col q-px-sm",
        dense: "",
        borderless: "",
        modelValue: _ctx.passwordBis,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.passwordBis) = $event)),
        debounce: "500",
        color: "green",
        type: !_ctx.showPassword ? 'password' : 'text'
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: !_ctx.showPassword ? 'visibility_off' : 'visibility',
            class: "cursor-pointer",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["modelValue", "type"])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.hasAcceptedDataPrivacy,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.hasAcceptedDataPrivacy) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_27, [
        _createTextVNode(_toDisplayString(_ctx.$t('Login.LoginSection.DataPrivacyCheckbox').split('%LINK%')[0]) + " ", 1),
        _createElementVNode("a", _hoisted_28, _toDisplayString(_ctx.$t('Login.LoginSection.DataPrivacyLink')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('Login.LoginSection.DataPrivacyCheckbox').split('%LINK%')[1]), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createVNode(_component_q_btn, {
        type: "submit",
        style: {"width":"100%"},
        class: "bg-secondary text-white q-mt-lg",
        onClick: _ctx.tryRegister,
        to: "home"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Login.LoginSection.ToRegister")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "row text-primary justify-end back-btn",
      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleShowRegister && _ctx.toggleShowRegister(...args)))
    }, _toDisplayString(_ctx.$t('Login.LoginSection.AlreadyRegistered')), 1)
  ]))
}