

import { defineComponent } from 'vue';

interface dataI {
}
export default defineComponent({
    props: ['user'],
    setup(): dataI {
        return {
        };
    }
});
