import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-primary text-h4 q-mt-xl q-mb-md row" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "q-mb-sm" }
const _hoisted_4 = { class: "q-pa-lg flex" }
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { class: "q-pa-md row" }
const _hoisted_7 = {
  key: 0,
  class: "col-xs-3 col-1",
  style: {"height":"100px","width":"100px"}
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col q-px-md" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "col-10" }
const _hoisted_12 = { class: "col-2 text-italic text-teal-6 text-subtitle2 flex justify-end align-start gt-sm" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  class: "bg-secondary text-white q-mr-sm flex items-center justify-center",
  style: {"border-radius":"50px","width":"25px","height":"25px"}
}
const _hoisted_16 = { class: "text-subtitle1 text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_MainContent = _resolveComponent("MainContent")!

  return (_openBlock(), _createBlock(_component_MainContent, { style: {"padding":"0 10%"} }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.items.length) + " " + _toDisplayString(_ctx.selectedRadio === 'all' ? _ctx.$t("SearchPage.Items.Any") : _ctx.$t("SearchPage.Types."+_ctx.selectedRadio)) + " " + _toDisplayString(_ctx.$t("SearchPage.Items.Found")) + " ", 1),
        (_ctx.searchQuery.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " " + _toDisplayString(_ctx.$t('SearchPage.Items.For')) + " \"" + _toDisplayString(_ctx.searchQuery) + "\"", 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("SearchPage.Research")), 1),
      _createVNode(_component_q_input, {
        filled: "",
        borderless: "",
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        debounce: "500",
        placeholder: _ctx.$t('Header.SecondNavBar.SearchPlaceholder')
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: "search",
            color: "secondary"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_option_group, {
          options: [
                    {label: _ctx.$t('SearchPage.Items.All'), value: 'all'},
                    {label: _ctx.$t('SearchPage.Items.Products'), value: 'product'},
                    {label: _ctx.$t('SearchPage.Items.News'), value: 'news'},
                    {label: _ctx.$t('SearchPage.Items.FAQ'), value: 'faq'}
                    ],
          type: "radio",
          modelValue: _ctx.selectedRadio,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRadio) = $event)),
          inline: ""
        }, null, 8, ["options", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (n, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", _hoisted_6, [
              (n.thumbnail)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: _ctx.getThumbnailUrl(n.thumbnail ?? ''),
                      style: {"object-fit":"cover","width":"100%","height":"100%"}
                    }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: "text-primary text-h6 row items-start justify-between",
                  onClick: ($event: any) => (_ctx.$router.push(n.url || '')),
                  style: {"cursor":"pointer"}
                }, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(n.title), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('SearchPage.Types.'+n.type)), 1)
                ], 8, _hoisted_10),
                _createElementVNode("div", {
                  class: "text-subtitle1 ellipsis-3-lines",
                  innerHTML: _ctx.compileMarkDown(n.description || '')
                }, null, 8, _hoisted_13),
                _createElementVNode("div", {
                  class: "row items-center q-mt-md",
                  onClick: ($event: any) => (_ctx.$router.push(n.url || '')),
                  style: {"cursor":"pointer"}
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_q_icon, { name: "chevron_right" })
                  ]),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("SearchPage.More")), 1)
                ], 8, _hoisted_14)
              ])
            ]),
            _createVNode(_component_q_separator)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}