
import { News } from '@/model/news';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import MainContent from '@/components/layout/main_content.vue';


import VueEasyLightbox from 'vue-easy-lightbox';
import { defineComponent } from 'vue';

// @ts-ignore
import { marked } from 'marked';

export default defineComponent({
    components: {
        VueEasyLightbox,
        MainContent
    },
    data() {
        return {
            currentImage: '',
            visible: false
        };
    },
    methods: {
        updateTitle() {
            setBreadcrumbs([{ title: this.currentNews?.title || '', path: '' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'News', this.currentNews?.title || '');
            }
        },
        show(img: string) {
            this.currentImage = img;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        compileMarkDown(text: string): string {
            return marked.parse(text);
        },
        processedDate(date: Date|undefined): string {
            return date ? this.$d(date, 'short') : '';
        }
    },
    mounted() {

    },
    computed: {
        selectedNewsId(): string {
            return this.$route.params.id as string;
        },
        currentIndex(): number {
            return News.news.value.findIndex(item => item.id === this.$route.params.id as string);
        },
        currentNews(): News | undefined {
            return News.news.value[this.currentIndex];
        },
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    },
    async beforeMount() {
        if (!News.news.value.length) {
            await News.init();
        }
        this.updateTitle();
    },
    watch: {
        currentNews: {
            handler(news: News) {
                this.updateTitle();
            },
            deep: true
        }
    }
});
