
import { defineComponent, Ref, ref } from 'vue';
import MainContent from '@/components/layout/main_content.vue';
import FaqCategory from '../model/faq_categorie';
import FaqSection from '../model/faq_section';
import GreenTitle from '@/components/shared/green_title.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import Product from '@/model/product';
import { searchInText } from '@/utils/search';

// @ts-ignore
import { marked } from 'marked';
import { baseUrl } from 'marked-base-url';

interface dataI {
  actualCategory: Ref<string>;
  faqs: Ref<FaqCategory[]>;
  products: Ref<Product[]>;
  search: Ref<string>;
}

export default defineComponent({
    components: {
        GreenTitle,
        MainContent
    },
    setup(): dataI {
        return {
            actualCategory: ref(''),
            faqs: ref([]),
            products: ref([]),
            search: ref('')
        };
    },

    methods: {
        changeCategory(a: string) {
            this.actualCategory = a;
        },
        compileMarkDown(text: string): string {
            return marked.parse(text);
        },
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'FAQ\'s', path: 'faq' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Faq.Title', 'Faq.Subtitle');
            }
        }
    },
    async mounted() {
        marked.use(baseUrl(this.baseApi || ''));

        await FaqCategory.init().then((faqs: any) => (this.faqs = faqs));
        await Product.init().then((products: any) => (this.products = products));

        this.updateSubNavBar();
        if (this.$route.query.category) {
            this.actualCategory = String(this.faqs.findIndex(item => item.title === this.$route.query.category));
            if (this.actualCategory === '-1') this.actualCategory = '0';
        } else {
            this.actualCategory = '0';
        }
    },
    computed: {
        tabValue(): string {
            return 'tabval_' + this.actualCategory;
        },
        filteredFaqs(): FaqSection[] {
            const searchQuery = this.search.toLowerCase();
            const results: FaqSection[] = [];
            const products = this.products;
            const faqs = this.faqs;

            if (!searchQuery) return [];

            products.forEach((p: Product) => {
                p.faqs.forEach((element, ind) => {
                    const productText = [element.answer, element.question];
                    const inProduct = searchInText(searchQuery, productText.join(' '));
                    if (inProduct !== false) {
                        results.push(new FaqSection({ id: ind, title: element.question, description: element.answer }));
                    }
                });
            });

            faqs.forEach(f => {
                f.sections.forEach(element => {
                    const faqText = [f.title, element.description, element.title];
                    const inFaq = searchInText(searchQuery, faqText.join(' '));
                    if (inFaq !== false) {
                        results.push(element);
                    }
                });
            });

            return results;
        },
        baseUrl(): string | undefined {
            return process.env.VUE_APP_PUBLIC_URL;
        },
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    }
});
