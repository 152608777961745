import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "row",
  style: {"gap":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenTitle = _resolveComponent("GreenTitle")!
  const _component_Card = _resolveComponent("Card")!
  const _component_EditorWizard = _resolveComponent("EditorWizard")!
  const _component_PruferWizard = _resolveComponent("PruferWizard")!
  const _component_Profile = _resolveComponent("Profile")!
  const _component_MainContent = _resolveComponent("MainContent")!

  return (_openBlock(), _createBlock(_component_MainContent, { style: {"min-height":"calc(100vh - 216px - 158px - 140px)"} }, {
    default: _withCtx(() => [
      (_ctx.chosenTab === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_GreenTitle, {
              class: "q-mb-md",
              title: "Dashboard"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Card, {
                class: "",
                title: _ctx.$t('Compatibility.CompatibilityRequest.Title'),
                style: _normalizeStyle(_ctx.$q.screen.gt.sm ? 'max-width: 250px; min-width: 250px' : 'min-width: 100%'),
                url: 'transport',
                notification: _ctx.currentUser.role.type === 'editor' ? _ctx.requests.length : _ctx.plans.filter(plan => plan.status === 'accepted').length,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTabs(1)))
              }, null, 8, ["title", "style", "notification"]),
              _createVNode(_component_Card, {
                title: _ctx.$t('Profile.Title'),
                style: _normalizeStyle(_ctx.$q.screen.gt.sm ? 'max-width: 250px; min-width: 250px' : 'min-width: 100%'),
                url: 'transport',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTabs(2)))
              }, null, 8, ["title", "style"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.chosenTab === 1 && _ctx.currentUser.role.type == 'editor')
        ? (_openBlock(), _createBlock(_component_EditorWizard, {
            key: 1,
            requests: {requests: _ctx.requests, plans: _ctx.plans},
            updateRequests: async () => { await _ctx.retrieveRequests() }
          }, null, 8, ["requests", "updateRequests"]))
        : _createCommentVNode("", true),
      (_ctx.chosenTab === 1 && _ctx.currentUser.role.type == 'reviewer')
        ? (_openBlock(), _createBlock(_component_PruferWizard, {
            key: 2,
            requests: _ctx.plans,
            updateRequests: async () => { await _ctx.retrieveRequests() },
            currentUser: _ctx.currentUser
          }, null, 8, ["requests", "updateRequests", "currentUser"]))
        : _createCommentVNode("", true),
      (_ctx.chosenTab === 2)
        ? (_openBlock(), _createBlock(_component_Profile, {
            key: 3,
            user: _ctx.currentUser
          }, null, 8, ["user"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}