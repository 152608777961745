import '@babel/polyfill';
import 'mutationobserver-shim';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import useQuasar from 'quasar/src/composables/use-quasar.js';import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import axios from 'axios';
import vueAxios from 'vue-axios';

createApp(App).use(Quasar, quasarUserOptions).use(i18n).use(store).use(vueAxios, axios).use(router).mount('#app');
