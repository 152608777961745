import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { ImageMedia } from './helper/image';

export class TransportSolution {

    id = 0;
    name = '';
    description = '';
    isContainer = false;
    isCoinfusion = false;
    cover: string = '';
    order = 0;

    constructor(id: number, name: string, description: string, isContainer: boolean, isCoinfusion: boolean, cover: string, order?: number) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.isContainer = isContainer;
        this.isCoinfusion = isCoinfusion;
        this.cover = cover;
        this.order = order || 0;
    }

    static TransportSolutions : Ref<TransportSolution[]> = ref([]);

    static async init() :Promise<Ref<TransportSolution[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/transport-solutions?populate=*&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;
        console.log('trasnsport data', data);
        this.TransportSolutions.value.splice(0);
        for (let i = 0; i < data.length; i++) {
            const image = data[i].attributes?.cover?.data ? new ImageMedia({ ...data[i].attributes.cover.data.attributes, id: data[i].attributes.cover.data.id }) : null;
            const c = new TransportSolution(
                data[i].id,
                data[i].attributes.name,
                data[i].attributes.description || '',
                data[i].attributes.isContainer || false,
                data[i].attributes.isCoinfusion || false,
                image ? image.largestUrl : '',
                data[i].attributes.order || 0
            );
            this.TransportSolutions.value.push(c);
        }

        return this.TransportSolutions;
    }


}
watch(i18n.global.locale, () => {
    if (TransportSolution?.TransportSolutions?.value?.length > 0) TransportSolution.init();
});


export default TransportSolution;
