
import MainContent from '@/components/layout/main_content.vue';
import BigCard from '@/components/shared/big_card.vue';
import GreenTitle from '@/components/shared/green_title.vue';

import { setBreadcrumbs } from '@/utils/breadcrumbs';

import { defineComponent, ref, Ref } from 'vue';

interface dataI {
  errorMsg: Ref<string>;
  newPassword: Ref<string>;
  newPasswordBis: Ref<string>;
}
export default defineComponent({
    components: {
        GreenTitle,
        BigCard,
        MainContent
    },
    setup(): dataI {
        return {
            errorMsg: ref(''),
            newPassword: ref(''),
            newPasswordBis: ref('')
        };
    },
    computed: {
        headerTitle(): string {
            return this.$t('Login.LoginSection.ResetPassword');
        }
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'hide', path: '' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Home.Title', 'Home.Subtitle');
            }
        },
        async tryResetPassword() {
            this.errorMsg = '';
            if (this.newPassword !== this.newPasswordBis) {
                this.errorMsg = this.$t('Login.LoginSection.NewPasswordError');
                return;
            }
            console.log('params:', this.$route.query.code);
            const res = await fetch(process.env.VUE_APP_API_URL + '/auth/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code: this.$route.query.code,
                    password: this.newPassword,
                    passwordConfirmation: this.newPasswordBis
                })
            });
            const json = await res.json();

            if (res.status === 200) {
                this.$router.push('/login');
            } else {
                this.errorMsg = json.message;
            }
        }
    },
    mounted() {
        this.updateSubNavBar();
    }
});
