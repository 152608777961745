
import { defineComponent, Ref, ref } from 'vue';
import GreenTitle from '@/components/shared/green_title.vue';
import Compatibility from '@/model/compatibilities';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

interface dataI {
  choosenMeds: Ref<Compatibility[]>;
  text: Ref<String>;

}

export default defineComponent({
    props: ['transport', 'tabOfSelectedMeds', 'updateSelectedMeds'],
    components: {
        GreenTitle
    },
    setup(): dataI {
        return { choosenMeds: ref([]), text: ref('') };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.CompatibilityRequest.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }
        },
        removeMedsByMed: function(s: Compatibility) {
            for (let i = 0; i < this.choosenMeds.length; i++) {
                if (this.choosenMeds[i].id === s.id) {
                    this.choosenMeds.splice(i, 1);
                    i = this.choosenMeds.length;
                }
            }

            this.updateSelectedMeds(this.choosenMeds);
        }


    },
    mounted() {
        this.updateSubNavBar();
        this.choosenMeds = this.tabOfSelectedMeds;
    }
});
