
import { defineComponent, Ref, ref } from 'vue';

import { setBreadcrumbs } from '@/utils/breadcrumbs';

import MainContent from '@/components/layout/main_content.vue';
import SolutionTab from '@/components/osmo/SolutionTab.vue';
import MedsTab from '@/components/osmo/MedsTab.vue';
import ResultTab from '@/components/osmo/ResultTab.vue';

import Solution from '@/model/solution';

import IWizardImage from '@/model/helper/wizardImage';

interface dataI {
    currentTab: Ref<number>;
    solutions: Ref<Solution[]>;
    additives: Ref<Solution[]>;
    wizardImages: Ref<IWizardImage[]>;
    solutionResetIndex: Ref<number>;
    medsResetIndex: Ref<number>;
}
export default defineComponent({
    components: {
        MainContent,
        SolutionTab,
        MedsTab,
        ResultTab
    },
    setup(): dataI {
        return {
            currentTab: ref(1),
            solutions: ref([]),
            additives: ref([]),
            wizardImages: ref([
                { src: 'step_1.png', alt: 'step_1_done.png' },
                { src: 'step_2.png', alt: 'step_2_done.png' },
                { src: 'step_3.png', alt: 'step_3_done.png' }
            ]),
            solutionResetIndex: ref(0), // Children forms are watching this value to reset their form when it changes,
            medsResetIndex: ref(0) // Children forms are watching this value to reset their form when it changes
        };
    },
    methods: {
        focusTab(tab: number) {
            this.currentTab = tab;
        },
        updateSolutions(solutions: Solution[]) {
            this.solutions = solutions;
        },
        updateAdditives(additives: Solution[]) {
            this.additives = additives;
        },
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Osmolarity.Title', path: 'osmo' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Osmolarity.Title', 'Osmolarity.Subtitle');
            }
        },
        routerBack() {
            this.$router.push('/');
        },
        nextPage() {
            this.currentTab += 1;
            window.scrollTo(0, 0);
        },
        previousPage() {
            if (this.currentTab > 1) {
                this.currentTab -= 1;
                window.scrollTo(0, 0);
            } else {
                this.routerBack();
            }
        }
    },
    mounted() {
        this.updateSubNavBar();
    }
});
