import Media from './helper/media';

export class FaqSection {
    id: string;
    title: string = '';
    description: string = '';
    links: Media[] = [];

    constructor({ id, title, description, links }: { id: string | number; title?: string; description?: string; links?: any[] }) {
        this.id = String(id);
        if (title) {
            this.title = title;
        }
        if (description) {
            this.description = description;
        }
        if (links) {
            this.links = links.map(item => Media.from(item));
        }
    }

}

export default FaqSection;
