import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-6" }
const _hoisted_2 = { class: "col q-mr-sm" }
const _hoisted_3 = { class: "text-primary q-ma-sm" }
const _hoisted_4 = {
  class: "flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg",
  style: {"height":"58px"}
}
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col q-mr-sm" }
const _hoisted_7 = { class: "text-primary q-ma-sm" }
const _hoisted_8 = {
  class: "flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg",
  style: {"height":"58px"}
}
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "col q-mr-sm" }
const _hoisted_11 = { class: "text-primary q-ma-sm" }
const _hoisted_12 = {
  class: "flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg",
  style: {"height":"58px"}
}
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "col q-mr-sm" }
const _hoisted_15 = { class: "text-primary q-ma-sm" }
const _hoisted_16 = {
  class: "flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg",
  style: {"height":"58px"}
}
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "col q-mr-sm" }
const _hoisted_19 = { class: "text-primary q-ma-sm" }
const _hoisted_20 = {
  class: "flex items-center text-subtitle2 q-ml-sm bg-grey q-px-lg",
  style: {"height":"58px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$q.screen.gt.sm ? 'row' : 'column', "justify-between q-pt-sm"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("Profile.Username")), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.user.username), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("Profile.Email")), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.user.email), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$q.screen.gt.sm ? 'row' : 'column', "justify-between q-pt-sm"])
    }, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("Profile.Role")), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.user.provider), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("Profile.Confirmed")), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.user.confirmed ? _ctx.$t("Yes") : _ctx.$t("No")), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$q.screen.gt.sm ? 'row' : 'column', "justify-between q-pt-sm"])
    }, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("Profile.CreationDate")), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.user.createdAt), 1)
        ])
      ])
    ], 2)
  ], 64))
}