
import { defineComponent, Ref, ref } from 'vue';

import Solution from '@/model/solution';

interface dataI {
    solutions: Ref<Solution[]>;
    freeSolution: Ref<Solution>;
}
export default defineComponent({
    props: {
        mobile: {
            type: Boolean,
            default: false
        },
        parentSolutions: {
            type: Object as () => Solution[],
            required: true
        },
        resetIndex: {
            type: Number,
            required: true
        }
    },
    setup(): dataI {
        return {
            solutions: ref([]),
            freeSolution: ref({
                id: 'free',
                title: '',
                description: '',
                baseOsmo: 0,
                quantity: 0,
                coverUrl: '',

                chosen: false,
                chosenAdd: false,
                compounds: []
            })
        };
    },
    computed: {
        allSolutions(): Solution[] {
            if (this.solutions.filter(solution => solution.id === 'free').length === 0) {
                return this.solutions.concat([this.freeSolution]);
            }
            return this.solutions;
        },
        currentLanguage(): string {
            return this.$i18n.locale;
        }
    },
    watch: {
        allSolutions: {
            handler(solutions: Solution[]) {
                this.$emit('solutions-changed', solutions);
            },
            deep: true
        },
        resetIndex: {
            handler() {
                this.allSolutions.forEach(solution => {
                    solution.quantity = 0;
                    if (solution.id === 'free') {
                        solution.title = this.$t('Osmolarity.Free.Title');
                        solution.baseOsmo = 0;
                    }
                });
            }
        },
        currentLanguage: {
            async handler(value) {
                this.solutions = [];
                this.freeSolution.title = this.$t('Osmolarity.Free.Title');
                this.freeSolution.description = this.$t('Osmolarity.Free.Description');
                const solutions = await Solution.initAdditif();
                this.solutions = solutions.value;
            }
        }
    },
    async beforeCreate() {
        if (this.parentSolutions && this.parentSolutions.length > 0) {
            this.solutions = this.parentSolutions;
        } else {
            const solutions = await Solution.initAdditif();
            this.solutions = solutions.value;
        }
        this.freeSolution.title = this.$t('Osmolarity.Free.Title');
        this.freeSolution.description = this.$t('Osmolarity.Free.Description');

    }
});
