
import GreenTitle from '@/components/shared/green_title.vue';
import MainContent from '@/components/layout/main_content.vue';
import Product from '@/model/product';
import FAQSection from '@/model/faq_section';
import FAQCategory from '@/model/faq_categorie';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { defineComponent, ref, Ref } from 'vue';

// @ts-ignore
import { marked } from 'marked';
import { baseUrl } from 'marked-base-url';


interface IData {
    currentFaqCategory: Ref<FAQCategory | undefined>,
    currentFaqCategoryId: Ref<string | undefined>
}

export default defineComponent({
    components: {
        GreenTitle,
        MainContent
    },
    data(): IData {
        return {
            currentFaqCategory: ref(),
            currentFaqCategoryId: ref('')
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'ProductCatalog.Title', path: 'back' }, { title: this.currentProduct?.name || 'Productinformationen', path: 'product' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'ProductCatalog.Title', this.currentProduct?.name);
            }
        },
        compileMarkDown(text: string): string {
            return marked.parse(text);
        }
    },
    computed: {
        currentIndex(): number {
            return Product.products.value.findIndex(item => item.id === this.$route.params.id as string);
        },
        currentProduct(): Product {
            return Product.products.value[this.currentIndex];
        },
        currentSections(): FAQSection[] | undefined {
            if (!this.currentFaqCategory) {
                return this.currentProduct?.faqs.map(item => ({ id: '', title: item.question, description: item.answer } as FAQSection));
            }
            return this.currentFaqCategory?.sections;
        },
        selectedProductId(): string {
            return this.$route.params.id as string;
        },
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    },
    async beforeMount() {
        marked.use(baseUrl(this.baseApi || ''));

        if (!Product.products.value.length) {
            await Product.init();
        }
        this.updateSubNavBar();
        if (this.currentProduct?.faqs && this.currentProduct?.faqs.length > 0) {
            this.currentFaqCategoryId = 't_' + this.currentProduct?.faqsCategories.length;
        } else {
            this.currentFaqCategory = this.currentProduct?.faqsCategories[0];
            this.currentFaqCategoryId = 't_0';
        }

    }
});
