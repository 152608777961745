/* eslint-disable */

// Refactodetarba


Number.prototype.toFixedL = function(value) {
    let t = Number(0 + this).toFixed(value);
    if (t.slice(-1) === '0' && this < 1) {
        t = Number(0 + this).toPrecision(1);
    }

    return t;
};
toFixedL = function(ctx, value) {
    return Number(ctx).toFixedL(value);
}
module.exports = {
    toFixedL,
    Number,
    number: Number
};
