<template>
    <div>

        <!-- Else if show pdf view -->
        <div v-if="showPdf">

            <!-- Pdf frame -->
            <div class="q-my-sm" style="height: 500px">
                <!-- embed pdf file -->
                <div v-if="pdfUrl" class="q-my-md pdf-container q-py-md q-px-sm bg-grey" style="max-height: 500px; overflow-y: scroll">
                    <VuePdfEmbed :source="pdfUrl" style="cursor: pointer" @click="() => openLink(pdfUrl)" />
                </div>
                <div v-else style="width: 100%; height: 100%">
                    <div class="bg-dark flex col" style="width: 100%; height: 100%; border-radius: 5px"></div>
                </div>
                <div class="bg-white upload-btn flex row justify-center items-center text-bold">
                    <!-- file input -->
                    <input name="file" id="file" class="input-btn" type="file" @change="onFileChange" >
                    <label for="file" class="q-ml-sm"><q-icon class="text-dark text-h6" name="add" />{{ $t('Requests.NewDocument') }}</label>
                </div>
            </div>

            <!-- Button to send prüfplan with confirmation -->
            <div class="row justify-between items-center q-mb-xl">
                <div class="q-mt-xl col-6 q-pt-sm ellipsis">{{ $t("Requests.SelectedFile") }}: <span class="text-bold" v-bind:class="fileErrorMessage ? 'text-red' : ''">{{ fileErrorMessage ? fileErrorMessage : currentFile?.name }}</span></div>
                <div class="PurpleToWhite q-px-lg q-mt-xl col-6" v-if="currentFile">
                    <q-btn no-caps flat @click="() => {sendPrufPlan()}" style="width: 100%" class="text-bold text-subtitle">
                        <span v-if="!isSending && !isSent">
                            {{ $t('Requests.SendPrufplan') }}
                        </span>
                        <div v-if="isSending">
                            <q-spinner
                                color="white"
                                size="1em"
                            ></q-spinner>
                        </div>
                        <span v-if="isSent && !isSending">
                            {{ $t('Requests.PrufplanSent') }}
                        </span>
                    </q-btn>
                </div>
            </div>

        </div>

        <!-- Questions -->
        <div>
            <Subtitle v-if="showPdf" :title="$t('Compatibility.CompatibilityRequest.Wizard.Clarification')" />

            <!-- Questions/answers chat -->
            <div class="q-my-sm fragen-field q-pt-lg">

                <!-- For each messages -->
                <div v-for="(frage, index) in request.conversation" :key="index">

                    <!-- Message datetime -->
                    <div class="row justify-center text-grey-6">{{ formatedDate(frage.date, request.conversation[index - 1]) }}</div>

                    <!-- If message isn't a rejection generated message, display the message content -->
                    <div v-if="frage.title !== 'Rejection'" v-bind:class="(frage.author?.id && frage.author.id === currentUser.id) ? 'row justify-end' : ''">
                        <div style="width: 50%">
                            <div style="border-radius: 20px; width: calc(100% - 20px)" class="q-ma-sm q-pa-sm" v-bind:class="(frage.author.id === currentUser.id) ? 'bg-secondary text-white' : 'bg-grey text-dark'">
                                <div>{{ frage.content }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- If message is a rejection generated one, display the rejection message and the pdf file -->
                    <div v-else v-bind:class="(frage.author?.id && frage.author.id === currentUser.id) ? 'row justify-end' : ''">
                        <div style="width: 50%">
                            <div style="border-radius: 20px; width: calc(100% - 20px)" class="q-ma-sm q-pa-sm" v-bind:class="(frage.author.id === currentUser.id) ? 'bg-secondary text-white' : 'bg-grey text-dark'">
                                <div class="q-pb-md"><i>{{ $t("Requests.AutomatedAnswer") }}</i></div>
                                <div v-if="frage.content && frage.content.split('/url/')[0]" class="q-pb-md">{{ frage.content.split('/url/')[0] }}</div>
                                <a v-if="frage.content && frage.content.split('/url/')[1]" :href="frage.content.split('/url/')[1]" target="_blank" v-bind:class="(frage.author.id === currentUser.id) ? 'text-white' : 'text-dark'">{{ $t("Requests.ClickToSeePdf") }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Input for messages -->
            <div class="col no-padding bg-grey">
                <q-input
                    borderless
                    debounce="500"
                    color="primary"
                    :placeholder="$t('Requests.InputPlaceholder')"
                    class="bg-grey q-mt-md q-px-lg"
                    v-model="textInput"
                    v-on:keyup.enter="() => sendConversation()"
                >
                </q-input>
            </div>

            <!-- Buttons to send pdf/messages -->
            <div class="row justify-between">

                <!-- Send pdf file -->
                <div v-if="!editor && request.status === 'accepted'" class="PurpleToWhite q-px-lg q-mt-lg">
                    <q-btn no-caps flat @click="() => {showPdf = true}" style="width: 100%" class="text-bold text-subtitle">
                        {{ $t('Requests.SelectPrufplan') }}
                    </q-btn>
                </div>
                <!-- Empty div to keep justify-between from parent -->
                <div v-else></div>

                <!-- Send message -->
                <div class="PurpleToWhite q-px-lg q-mt-lg">
                    <q-btn no-caps flat @click="() => {sendConversation()}" style="width: 100%" class="text-bold text-subtitle">
                        {{ editor ? $t('Requests.SendQuestion') : $t('Requests.SendResponse') }}
                    </q-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Media from '@/model/helper/media';
import VuePdfEmbed from 'vue-pdf-embed';

const Subtitle = {
    props: ['title'],
    render() {
        return (<div>
            <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{this.title}</div>
            <div class="row">
                <div class="col-3 subtitle-thick" />
                <div class="col-9 subtitle-thin" />
            </div>
        </div>);
    }
};

export default defineComponent({
    props: ['editor', 'request', 'goBack', 'currentTab'],
    data() {
        return {
            textInput: '',
            showPdf: false,
            pdfUrl: '',
            isSending: false,
            isSent: false,
            currentFile: null,
            fileErrorMessage: '',
            requestU: this.request
        };
    },
    components: {
        VuePdfEmbed,
        Subtitle
    },
    beforeMount() {
        if (this.request.testSchedule && this.request.testSchedule.url) {
            this.pdfUrl = process.env.VUE_APP_PUBLIC_URL + this.request.testSchedule.url;
        }
        this.showPdf = Number(this.currentTab) === 1 && !this.editor ? true : this.showPdf;
    },
    computed: {
        currentUser() {
            return this.$parent.$parent.$parent.$parent.currentUser;
        }
    },
    watch: {
        request() {
            this.$nextTick(() => {
                const div = this.$el.querySelector('.fragen-field');

                if (div) {
                    div.scrollTop = div.scrollHeight;
                }
            });
        }
    },
    methods: {
        async sendConversation() {
            if (this.textInput.length <= 0) {
                return;
            }
            const tmp = this.textInput;
            this.textInput = '';
            await this.request.sendConversation({
                title: 'Frage',
                content: tmp,
                date: new Date(),
                author: this.$parent.$parent.$parent.$parent.currentUser.id
            });

            this.$emit('updateRequests');
        },
        async addMediaToRequest(data) {
            this.requestU.testSchedule = data;
            this.requestU.revisions = this.request.revisions.concat([
                {
                    status: 'pending',
                    date: new Date(),
                    author: this.$parent.$parent.$parent.$parent.currentUser.id
                }
            ]);
            this.requestU.status = 'proposed';
            this.requestU.save(['testSchedule', 'revisions', 'status']);
        },
        async sendPrufPlan() {
            this.isSending = true;

            const pdf = await Media.upload(this.currentFile);
            this.pdfUrl = pdf.url;
            this.addMediaToRequest(pdf);

            this.isSending = false;
            this.isSent = true;
            this.goBack();
        },
        onFileChange(file) {
            if (file.target.files[0] && file.target.files[0].type.includes('pdf')) {
                this.currentFile = file.target.files[0];
                this.fileErrorMessage = '';
            } else {
                this.currentFile = null;
                this.fileErrorMessage = 'Dieses Dokument ist kein PDF';
            }
        },
        formatedDate(date, previous) {
            if (previous) {
                const currentDate = date.toLocaleDateString(this.$i18n.locale, {
                    hour: 'numeric',
                    minute: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });
                const previousDate = previous.date.toLocaleDateString(this.$i18n.locale, {
                    hour: 'numeric',
                    minute: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });

                if (currentDate === previousDate) {
                    return '';
                }
            }
            return date.toLocaleDateString(this.$i18n.locale, {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                month: 'long'
            });
        },
        openLink(link) {
            window.open(link, '_blank');
        }
    }
});
</script>

<style lang="sass">
@import '../../../styles/quasar.variables.scss'

.fragen-field
    border: 2px solid $grey
    height: 600px
    overflow-y: scroll

.upload-btn
    height: 30px
    width: 100%
    border-radius: 7px
    border: 2px solid $accent

.upload-btn label
    cursor: pointer

.upload-btn:click
    border: 2px solid $primary

.input-btn
    display: none

.pdf-container
    border-radius: 7px
    border: 2px solid $accent

</style>
