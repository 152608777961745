
import CompatibilityPlan from '@/model/compatibility-plan';
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['requests', 'currentTab', 'openRequest', 'searching'],
    methods: {
        formatedDate(date: string) {
            if (!date) {
                return '';
            }
            const requestDate = new Date(date).toLocaleString(this.$i18n.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
            return requestDate;
        }
    },
    computed: {
        currentTabRequests(): CompatibilityPlan[] {
            const index = Number(this.currentTab);

            if (index === 0) {
                return this.requests;
            }
            const currentStatus = index === 1
                ? ['accepted', 'ready']
                : index === 2
                    ? ['proposed']
                    : index === 3 ? ['scheduled'] : ['completed'];

            const currentTab = this.requests.filter((req: CompatibilityPlan) => currentStatus.includes(req.status));

            return currentTab;
        },
        otherTabRequests(): CompatibilityPlan[] {
            const index = Number(this.currentTab);

            if (index === 0 || index === 4) {
                return [];
            }
            const currentStatus = index === 1
                ? ['accepted', 'ready']
                : index === 2
                    ? ['proposed']
                    : index === 3 ? ['scheduled'] : ['completed'];

            const otherTab = this.requests.filter((req: CompatibilityPlan) => !currentStatus.includes(req.status));

            return otherTab;
        }
    }
});
