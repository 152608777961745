

import { defineComponent } from 'vue';

import MainContent from '@/components/layout/main_content.vue';
import FrPrivacy from './Privacy/FrPrivacy.vue';
import DePrivacy from './Privacy/DePrivacy.vue';

import { setBreadcrumbs } from '@/utils/breadcrumbs';

interface dataI {
}

export default defineComponent({
    components: {
        MainContent,
        FrPrivacy,
        DePrivacy
    },
    props: {
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'hide', path: '' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Home.Title', 'Privacy.Title');
            }
        }
    },
    mounted() {
        this.updateSubNavBar();
    },
    computed: {
    },
    async beforeMount() {
    }
});
