<template>
    <!-- Return button -->
    <div class="row justify-between q-mt-md primary-underline">
        <div class="row items-center cursor-pointer" @click="goBack()">
            <div class="viewer-back-btn text-accent text-body1 text-weight-bold flex items-center justify-center">&lt;</div>
            <div class="q-ml-md">{{ $t('PreviousButton') }}</div>
        </div>
        <div class="row items-center cursor-pointer btn-click" @click="refreshPage()">
            <div class="viewer-back-btn text-accent text-body1 text-weight-bold flex items-center justify-center">
                <q-icon name="sync" />
            </div>
            <div class="q-ml-md">{{ $t("Refresh") }}</div>
        </div>
    </div>

    <Subtitle :title="showTestReport ? $t('Compatibility.CompatibilityRequest.Wizard.Request') : tab == 2 ? $t('Compatibility.CompatibilityRequest.Wizard.Request') : $t('Compatibility.CompatibilityRequest.Wizard.Request')" />

    <!-- Informations and input selector about the request/plan -->
    <div class="q-my-md">
        <div class="row justify-between q-mb-lg">
            <div class="col q-mr-sm">
                <div class="text-primary q-ma-sm">{{ $t('Compatibility.CompatibilityRequest.Editor') }}</div>
                <div class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ request?.reviewerEditor.username || '-' }}</div>
            </div>
            <div class="col q-ml-sm">
                <div class="text-primary q-ma-sm">{{ $t("Requests.Status") }}</div>
                <div class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ request?.status || '-' }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="text-primary q-ma-sm">{{ $t('Compatibility.CompatibilityRequest.LabTester') }}</div>
                <div class="flex items-center text-subtitle2 q-ml-sm" style="height: 58px">{{ request?.reviewerLaboratory?.username || '-' }}</div>
            </div>
            <div class="col row justify-between">
                <div class="col q-ml-sm">
                    <div class="text-primary q-ma-sm">{{ $t('Requests.CreationDate') }}</div>
                    <div class="flex items-center q-py-md q-pl-sm text-subtitle2">{{ formatedDate(request.createdAt) }}</div>
                </div>
                <div class="col q-ml-sm">
                    <div class="text-primary q-ma-sm">{{ $t('Requests.EditedDate') }}</div>
                    <div class="flex items-center q-py-md q-pl-sm text-subtitle2">{{ formatedDate(request.updatedAt) }}</div>
                </div>
            </div>
        </div>
        <div v-if="tab == 2" class="row justify-between q-mb-lg">
            <div class="col q-mr-sm">
                <div class="text-primary q-ma-sm">{{ $t("Requests.ProjectNumber") }}</div>
                <div class="flex items-center text-subtitle2 q-ml-lg" style="height: 58px">{{ request?.id || '-' }}</div>
            </div>
        </div>

        <!-- Transport solution informations -->
        <div v-if="!showTestReport">
            <Subtitle :title="$t('Compatibility.CarrierSolutions.Title')" />
            <div
                class="bg-grey q-my-md q-mx-sm q-py-xs q-px-md"
                style="border-radius: 50px;">
                <div class="text-capitalize text-bold text-dark text-subtitle1">{{ request?.compatibility?.transportSolution?.name || '-' }}</div>
            </div>

            <!-- Medicin informations -->
            <div v-if="tab < 2">
                <Subtitle :title="$t('Compatibility.Drug')" />

                <div class="q-mt-md">
                    <div
                        class="bg-grey q-my-xs q-mx-sm q-py-xs q-px-md"
                        style="border-radius: 50px;"
                        v-for="medicin in [request?.compatibility?.medicin || []]" :key="medicin.id">
                        <div class="text-capitalize text-bold text-dark text-subtitle1">{{ medicin.name + ' - ' + medicin.activeAgent }}</div>
                    </div>
                </div>
            </div>

            <!-- Fragen ecotox labor -->
            <div v-if="tab < 2">

                <!-- Anfrager infos -->
                <Subtitle :title="$t('Compatibility.CompatibilityRequest.Wizard.Requester')" />
                <AnfragerInfos :request="request.compatibilityRequest"/>
            </div>

            <div v-if="tab == 2">
                <Preparat :editor="false" :request="request" :setShowTestReport="(bool) => setShowTestReport(bool)" />
            </div>

            <!-- Chat -->
            <div>
                <Subtitle :title="$t('Compatibility.CompatibilityRequest.Wizard.Clarification')" />
                <RequestFragen v-if="request" :editor="false" :request="request" :goBack="() => goBack()" :currentTab="tab" @updateRequests="() => refreshPage()" />
            </div>

            <!-- Revisions -->
            <div v-if="request?.revisions?.length">
                <Subtitle title="Revisions" class="q-mt-xl"/>
                <div class="q-pa-xs">
                    <div class="row q-pa-md q-mt-sm requests-header items-center text-primary">
                        <div class="col-6 ellipsis">{{ $t("Requests.Revision") }}</div>
                        <div class="col-2 ellipsis">{{ $t("Requests.EditedDate") }}</div>
                        <div class="col-2 ellipsis">{{ $t("Compatibility.CompatibilityRequest.Editor") }}</div>
                        <div class="col-2 ellipsis">{{ $t("Requests.Status") }}</div>
                    </div>
                    <div
                        class="row q-pa-md text-secondary items-start"
                        v-for="(req, index) in request?.revisions || []"
                        v-bind:class="index % 2 !== 0 ? 'even' : ''"
                        :key="req.id">
                        <div class="col-6 ellipsis">{{ index }}</div>
                        <div class="col-2 ellipsis">{{ formatedDate(req.date) }}</div>
                        <div class="col-2 ellipsis">{{ req.author?.username }}</div>
                        <div class="col-2 ellipsis">{{ req.status }}</div>
                    </div>
                </div>
            </div>
        </div>

        <TestReport v-if="showTestReport" :request="request" :goBack="() => goBack()" />
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import RequestFragen from '../shared/request_fragen.vue';
import Preparat from '../shared/preparat.vue';
import TestReport from '../shared/test_report.vue';
import AnfragerInfos from '../shared/anfrager_infos.vue';

const Subtitle = {
    props: ['title'],
    render() {
        return (<div>
            <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{this.title}</div>
            <div class="row">
                <div class="col-3 subtitle-thick" />
                <div class="col-9 subtitle-thin" />
            </div>
        </div>);
    }
};

export default defineComponent({
    props: ['request', 'tab', 'goBack'],
    data: function() {
        return {
            model: '',
            date: ref(''),
            showTestReport: ref(false)
        };
    },
    components: {
        Subtitle,
        RequestFragen,
        Preparat,
        TestReport,
        AnfragerInfos
    },
    methods: {
        async refreshPage() {
            await this.$parent.$parent.$parent.retrieveRequests();
        },
        setShowTestReport(bool) {
            this.showTestReport = bool;
        },
        formatedDate(date) {
            if (!date) return '';
            const formated = new Date(date).toLocaleDateString(this.$i18n.locale, {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                month: 'long'
            });

            return formated;
        }
    }
});
</script>

<style lang="sass">
@import '../../../styles/quasar.variables.scss'

.viewer-back-btn
  background: $secondary
  width: 25px
  height: 25px

.primary-underline
    padding-bottom: 10px
    border-bottom: 2px solid $primary

.subtitle-thick
    border-bottom: 5px solid $primary

.subtitle-thin
    border-bottom: 3px solid $primary

</style>
