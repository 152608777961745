
import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';

// To be used on app.use(Quasar, { ... })

export default {
    config: {
        all: true,
        components: [
            'QImg'
        ],
        framework: {
            all: true,
            autoImportComponentCase: 'combined'
        }
    },
    all: true,
    components: [
        'QImg'
    ],
    framework: {
        all: true,
        autoImportComponentCase: 'combined'
    }
};
