
import { defineComponent, Ref, ref } from 'vue';
import { login } from '@/utils/auth';

interface dataI {
    username: Ref<string>;
    password: Ref<string>;
    attempt: Ref<boolean>;
    errorMsg: Ref<string>;
}
export default defineComponent({
    components: {
    },
    props: {
        registered: {
            type: Boolean,
            default: false
        }
    },
    setup(): dataI {
        return {
            username: ref(''),
            password: ref(''),
            attempt: ref(false),
            errorMsg: ref('')
        };
    },
    methods: {
        toggleShowRegister() {
            this.$emit('toggleShowRegister');
        },
        toggleShowForgot() {
            this.$emit('toggleShowForgot');
        },
        async loginFunction() {
            this.errorMsg = '';
            if (!this.username || !this.password) {
                this.errorMsg = this.$t('Login.ErrorHandling.EmptyFields');
                return;
            }

            // Try login as bbraun user
            await login(this.username, this.password, false);
            if (!window.localStorage.getItem('jwt')) {
                // Try login as reviewer
                await login(this.username, this.password, true);
            }

            // If login is still not successful, show error message
            if (!window.localStorage.getItem('jwt')) {
                this.errorMsg = this.$t('Login.ErrorHandling.InvalidCredentials');
                return;
            }
            this.attempt = true;
            this.password = '';
        }
    }
});
