
import { defineComponent } from 'vue';
import MedList from '@/components/kompat/med_list.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

export default defineComponent({
    props: [
        'updateSelectedMeds',
        'tabOfSelectedMeds',
        'perfusionWay',
        'transport'
    ],
    components: {
        MedList
    },
    data() {
        return {
            hideElements: false
        };
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.SelectDrug.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }
        },
        setHideElements(b: boolean) {
            this.hideElements = b;
            return b;
        }
    },
    mounted() {
        this.updateSubNavBar();
    }
});
