
export interface StrapiMediaFormat {
    id?: string;

    name: string;
    hash: string;
    ext: string;
    mime: string;

    size: number;

    url: string;
    height?: number;
    width?: number;

    /* eslint-disable id-match */
    created_at: string;
    /* eslint-disable id-match */
    updated_at: string;
}

export interface StrapiDisplayMedia extends StrapiMediaFormat {

    formats: { [format: string]: StrapiMediaFormat };
}

export class Media implements StrapiMediaFormat {

    id: string;
    name: string;
    hash: string;
    ext: string;
    mime: string;

    size: number;

    url: string;

    /* eslint-disable id-match */
    created_at: string;
    /* eslint-disable id-match */
    updated_at: string;

    constructor(
        id: string,
        name: string,
        hash: string,
        ext: string,
        mime: string,

        size: number,

        url: string,
        created_at: string,
        updated_at: string
    ) {
        this.id = id;
        this.name = name;
        this.hash = hash;
        this.ext = ext;
        this.mime = mime;
        this.size = size;
        this.url = url;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }

    static from(raw: any): Media {
        return new Media(
            raw.id,
            raw.name,
            raw.hash,
            raw.ext,
            raw.mime,
            raw.size,
            raw.url,
            raw.created_at,
            raw.updated_at
        );
    }

    static async upload(raw: File): Promise<Media> {
        const formdata = new FormData();
        formdata.append('files', raw, raw.name);

        const res = await fetch(process.env.VUE_APP_API_URL + '/upload/', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
            },
            body: formdata
        });
        const data = (await res.json());
        console.log('upload daa', data);

        return Media.from(data.pop());
    }
}

export default Media;
