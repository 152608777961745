

import { defineComponent } from 'vue';
import MainContent from '@/components/layout/main_content.vue';

export default defineComponent({
    components: {
        MainContent
    },
    props: []
});

