
import { defineComponent } from 'vue';

interface Data {
}

export default defineComponent({
    props: ['title'],

    data(): Data {
        return {};
    }
});
