
import { defineComponent, PropType } from 'vue';
import IWizardImage from '@/model/helper/wizardImage';


interface dataI {

}
export default defineComponent({
    props: {
        step: Number,
        images: {
            type: Object as PropType<IWizardImage[]>
        },
        changeTabWizard: Function
    },
    setup(): dataI {
        return {
        };
    },
    methods: {
        getColSize(): number {
            if (this.images && this.images.length > 0) {
                return Math.floor(12 / (this.images.length - 1));
            }
            return 0;
        }
    }
});
