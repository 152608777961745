import FaqSection from './faq_section';
import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { extractLocalizedId } from './helper/locale';
import { fromDataType } from './helper/interface';

export class FaqCategory {

    id: string;
    title: string = '';
    sections: FaqSection[] = [];
    order: number = 0;

    static faqCategories : Ref<FaqCategory[]> = ref([]);

    constructor({ id, title, sections, order }: { id: string | number; title?: string; sections?: FaqSection[]; order?: number }) {
        this.id = String(id);
        if (title) {
            this.title = title;
        }
        if (sections) {
            this.sections = sections.map((item: any) => {
                return new FaqSection({ id: item.id, title: item.title, description: item.description, links: item.links });
            });
        }
        this.order = order || 0;
    }

    static async init() :Promise<Ref<FaqCategory[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/faq-categories?populate[sections][populate]=*&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;
        this.faqCategories.value.splice(0);
        for (let i = 0; i < data.length; i++) {
            const d = fromDataType(data[i]);
            console.log(data[i], d);
            const c = new FaqCategory({ ...d, id: extractLocalizedId(d) });
            this.faqCategories.value.push(c);
        }

        this.faqCategories.value.sort((a: FaqCategory, b: FaqCategory) => {
            return a.order - b.order;
        });

        return this.faqCategories;
    }

}

watch(i18n.global.locale, () => {
    if (FaqCategory.faqCategories.value.length > 0) FaqCategory.init();
});


export default FaqCategory;
