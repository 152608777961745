<template>
  <div>
    <!-- <q-img src="@/assets/step_1_done.png"></q-img> -->

    <div style="height: 40px"></div>
    <div class="row no-padding">
      <div class="col no-padding">
        {{ $t("Compatibility.Introduction.IntroductionParagraph1") }}
      </div>
    </div>
    <div style="height: 30px"></div>
    <div class="row no-padding" style="height: 80px">
      <div class="col no-padding">
        {{ $t("Compatibility.Introduction.IntroductionParagraph2") }}
      </div>
    </div>

    <!-- FIRST SECTION -->
    <GreenTitle
      :title="$t('Infusion.InContainer.Title')"
    ></GreenTitle>
    <div class="row q-pt-lg">
      <div class="col-2 no-padding q-ml-md" style="width: 150px;">
        <q-img
          src="@/assets/image_behaltenis.png"
          class="image-card"
          spinner-color="white"
          style="width: 150px;"
          fit="contain"
        />
      </div>
      <div class="col no-padding q-ml-md">
        {{ $t("Infusion.InContainer.Text") }}
      </div>
    </div>

    <div class="row" style="height: 20px">
      <div class="col"></div>
    </div>

    <!-- SECOND SECTION -->
    <GreenTitle
      :title="$t('Infusion.InLine.Title')"
    ></GreenTitle>
    <div class="row" style="height: 20px">
      <div class="col"></div>
    </div>
    <div class="row">
      <div class="col-2 no-padding q-ml-md" style="width: 150px;">
        <q-img
          src="@/assets/image_leitung.png"
          class="image-card"
          spinner-color="white"
          style="width: 150px"
          fit="contain"
        />
      </div>
      <div class="col no-padding q-ml-md">
        {{ $t('Infusion.InLine.Text') }}
      </div>
    </div>
  </div>
</template>


<script>
import GreenTitle from '@/components/shared/green_title.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
export default {
    components: {
        GreenTitle
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.Introduction.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }

        }
    },
    mounted() {
        this.updateSubNavBar();
    }
};
</script>
<style lang="scss">
@import '@/styles/kompat.scss';
</style>
