
import { defineComponent, Ref, ref } from 'vue';

interface dataI {
    username: Ref<string>;
}
export default defineComponent({
    components: {
    },
    setup(): dataI {
        return {
            username: ref('')
        };
    },
    methods: {
        toggleForgotRegister() {
            this.$emit('toggleShowForgot');
        },
        async tryForgot() {
            const res = await fetch(process.env.VUE_APP_API_URL + '/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.username,
                    locale: this.$i18n.locale
                })
            });
            const json = await res.json();

            if (json.error) {
                console.log(json.error);
            } else {
                this.toggleForgotRegister();
                this.$emit('mailSent');
            }
        }
    }
});
