import { Ref, ref } from 'vue';

const myUsername: Ref<string> = ref('');
const currentJwt: Ref<string> = ref('');


interface breadcrumbsI {
    title: string,
    path: string
}

const bcTab: Ref<breadcrumbsI[]> = ref([]);

function setBreadcrumbs(tab: breadcrumbsI[]) {
    const breadcrumbsTab: breadcrumbsI[] = [];
    for (let i = 0; i < tab.length; i++) {
        const test: breadcrumbsI = { title: '', path: '' };
        test.title = tab[i].title;
        test.path = tab[i].path;
        breadcrumbsTab.push(test);
    }
    bcTab.value = breadcrumbsTab;


};

export {
    setBreadcrumbs,
    bcTab,
    myUsername,
    currentJwt
};

