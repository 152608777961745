
import * as Levenshtein from 'fast-levenshtein';


export function searchInText(query: string, source: string): number | boolean {
    const wordsMap: {[key: string]: number} = {};
    const queryWords = query.split(/[\s]+/);
    const sourceWords = source.split(/[\s]+/);

    for (const word of queryWords) {
        for (const sWord of sourceWords) {
            const distance = Levenshtein.get(word, sWord, { useCollator: true });

            if (distance < word.length / 4 || sWord.toLocaleLowerCase().includes(word.toLowerCase())) {
                if (!wordsMap[word]) {
                    wordsMap[word] = distance;
                } else if (distance < wordsMap[word]) {
                    wordsMap[word] = distance;
                }
            }
        }
    }
    const vals = Object.values(wordsMap);
    if (vals.length > queryWords.length / 2) {
        const wordsLength = queryWords.length > 1 ? Number(queryWords.reduce((a, b) => String(a.length + b.length))) / queryWords.length : (queryWords.pop() || '').length;
        return (vals.reduce((a, b) => a + b) / vals.length) - ((queryWords.length - vals.length) * (wordsLength / 3));
    } else {
        return false;
    }


}
