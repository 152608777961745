<template>
  <div>
    <div style="height: 100px"></div>

    <div class="row">
      <div class="col-2 gt-sm"></div>
    <div class="row col wrap" style="min-height: 400px">

      <!-- Behaltenis -->
      <button
        no-caps
        @click="changePage('Kompat3'), changePerfusionWay('container')"
        class="col q-ma-sm KompatDrugAdditionButton no-padding bg-behaltenis text-dark"
        style="text-decoration: none; cursor: pointer; min-width: 200px; box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1)"
        :disabled="!transport?.isContainer"
      >
          <div class="flex items-end q-ma-md no-padding"  style="min-height:90%; max-width: 90%; overflow-y: hidden">
            <q-card class="left " style="height:230px; max-width: 100%">
              <q-card-section class="q-pl-md q-pb-none q-pt-xs">
                <div class="text-h5 Rotis">{{ $t('Infusion.InContainer.Title') }}</div>
              </q-card-section>

              <div class="q-pa-sm" style="overflow-wrap: break-word;">
                {{ $t('Infusion.InContainer.Text') }}
              </div>

              <q-separator dark />
            </q-card>
          </div>
      </button>

      <!-- Leitung -->
      <button
        no-caps
        @click="changePage('Kompat3'), changePerfusionWay('coinfusion')"
        class="col q-ma-sm KompatDrugAdditionButton no-padding bg-leitung text-dark"
        style="text-decoration: none; cursor: pointer; min-width: 200px; box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1)"
        :disabled="!transport?.isCoinfusion"
      >
          <div class="flex items-end q-ma-md no-padding"  style="min-height:90%; max-width: 90%; overflow-y: hidden">
            <q-card class="left" style="height:230px; max-width: 100%;">
              <q-card-section class="q-pl-sm q-pb-none q-pt-xs">
                <div class="text-h5 Rotis">{{$t('Infusion.InLine.Title')}}</div>
              </q-card-section>

              <div class="q-pa-sm" style="overflow-wrap: break-word;">
                {{$t('Infusion.InLine.Text')}}
              </div>

              <q-separator dark />
            </q-card>
        </div>
      </button>
    </div>
    <div class="col-2 gt-sm"></div>
    </div>
    <!--  -->
    <div class="row" style="height: 100px">
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import TransportSolution from '@/model/transports';

export default {
    props: {
        changePage: Function,
        changePerfusionWay: Function,
        tabOfViewName: [],
        currentPage: Number,
        transport: TransportSolution
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'Compatibility.Title', path: 'kompat' }, { title: 'Compatibility.DrugAddition.Title', path: 'none' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.Title', 'Compatibility.SelectDrug.Title');
            }
        }
    },
    mounted() {
        this.updateSubNavBar();
    }
};
</script>

<style lang="scss">
@import '@/styles/quasar.variables';

.card-container {
  padding: 0 15px;
}

.bg-behaltenis{
  background-image: url('../../assets/image_behaltenis2.png');
}

.bg-leitung{
  background-image: url('../../assets/image_leitung.png');
}

.bg-behaltenis, .bg-leitung {
  box-sizing: border-box;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid white;

  &:hover {
    border: 3px solid $secondary !important;
  }
}

</style>

<style lang="scss">
@import '@/styles/quasar.variables';

//Mobile
@media (max-width: $breakpoint-xs-max) {
  .KompatDrugAdditionButton{
    height: 270px;
  }
}


//Desktop
@media (min-width: $breakpoint-xs-max){
  .KompatDrugAdditionButton{
    width: 350px !important;
    height: 400px;
  }
}
</style>
