import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row justify-end" }
const _hoisted_2 = { class: "PurpleToWhite q-mr-md" }
const _hoisted_3 = { class: "PurpleToWhite" }
const _hoisted_4 = { class: "q-pa-md q-gutter-sm" }
const _hoisted_5 = { class: "text-h6" }
const _hoisted_6 = { class: "text-subtitle2 text-grey-6 text-justify" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          flat: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('acceptRequest', []))),
          style: {"width":"100%"},
          class: "text-bold text-subtitle"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Requests.RejectRequest")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          flat: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = true)),
          style: {"width":"100%"},
          class: "text-bold text-subtitle"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Requests.CreatePlan")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.showModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, { style: {"width":"100vw"} }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "q-mb-md" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Requests.ChooseMeds")), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("Requests.ChooseMedsSubtitle")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-pt-none flex-col" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.models, (medicin, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "bg-grey q-my-xs q-py-xs q-px-md cursor-pointer",
                      style: {"border-radius":"50px"},
                      onClick: () => { medicin.value = !medicin.value },
                      key: index
                    }, [
                      _createVNode(_component_q_checkbox, {
                        modelValue: medicin.value,
                        "onUpdate:modelValue": ($event: any) => ((medicin.value) = $event),
                        label: medicin.medicin.name
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                    ], 8, _hoisted_7))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_q_btn, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('acceptRequest', _ctx.models.filter(med => med.value)))),
                    flat: "",
                    label: _ctx.$t('Requests.CreatePlans'),
                    color: "primary"
                  }, null, 8, ["label"]), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}