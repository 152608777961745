
import { defineComponent, Ref, ref } from 'vue';

import Solution from '@/model/solution';

interface dataI {
    results: Ref<{
        totalSolutionVolume: number;
        totalSolutionOsmo: number;
        compounds: { name: String, osmolarity: number, quantity: number }[];
    }>;
}
export default defineComponent({
    props: {
        solutions: {
            type: Object as () => Solution[],
            required: true
        },
        additives: {
            type: Object as () => Solution[],
            required: true
        },
        mobile: {
            type: Boolean
        }
    },
    watch: {
        solutions: {
            handler() {
                this.calculateOsmo(this.solutions, this.additives);
            },
            deep: true
        },
        additives: {
            handler() {
                this.calculateOsmo(this.solutions, this.additives);
            },
            deep: true
        }
    },
    setup(): dataI {
        return {
            results: ref({
                totalSolutionVolume: 0,
                totalSolutionOsmo: 0,
                compounds: []
            })
        };
    },
    methods: {
        calculateOsmo(solutions: Solution[], additives: Solution[]) {
            let cOsmo = 0;
            let cQuantity = 0;
            solutions.forEach(solution => {
                cQuantity += solution.quantity;
            });
            additives.forEach(additiv => {
                cQuantity += additiv.quantity;
            });

            solutions.forEach(solution => {
                cOsmo += (solution.baseOsmo * (solution.quantity / cQuantity));
            });
            additives.forEach(additiv => {
                cOsmo += (additiv.baseOsmo * (additiv.quantity / cQuantity));
            });

            // Save volume / osmolariy values
            this.results.totalSolutionOsmo = this.round(cOsmo);
            this.results.totalSolutionVolume = this.round(cQuantity);

            this.results.compounds = [];

            // Save selected solutions / additives
            this.solutions.filter((solution: Solution) => {
                return solution.quantity;
            }).forEach((solution: Solution) => {
                solution.compounds.forEach((compound: { name: String, osmolarity: number }) => {
                    this.addCompoundToList(compound, solution.quantity);
                });
            });

            this.additives.filter((solution: Solution) => {
                return solution.quantity;
            }).forEach((solution: Solution) => {
                solution.compounds.forEach((compound: { name: String, osmolarity: number }) => {
                    this.addCompoundToList(compound, solution.quantity);
                });
            });
        },
        addCompoundToList(compound: { name: String, osmolarity: number }, quantity: number) {
            const compoundIndex = this.results.compounds.findIndex((c: { name: String, osmolarity: number }) => {
                return c.name === compound.name;
            });

            if (compoundIndex === -1) {
                this.results.compounds.push({
                    name: compound.name,
                    osmolarity: this.round(compound.osmolarity * (quantity / this.results.totalSolutionVolume)),
                    quantity: this.round(quantity)
                });
            } else {
                this.results.compounds[compoundIndex].osmolarity += this.round(compound.osmolarity * (quantity / this.results.totalSolutionVolume));
                this.results.compounds[compoundIndex].quantity += this.round(quantity);
            }
        },
        round(n: number) {
            return parseFloat(n.toPrecision(5));
        }
    }
});
