import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { ImageMedia } from './helper/image';
import { extractLocalizedId } from './helper/locale';

interface Compounds {
    name: string;
    osmolarity: number;
}

export class Solution {

    id: string = '0';
    title: string = '';
    baseOsmo: number = 0;
    coverUrl: string = '';
    description: string = '';

    chosen: boolean = false;
    chosenAdd: boolean = false;
    quantity: number = 0;


    compounds: Compounds[] = [];

    constructor(
        id: number | string,
        title?: string,
        osmoBase?: number,
        cover?: ImageMedia | null,
        description?: string,
        compounds?: Compounds[]
    ) {
        this.id = String(id);
        this.title = title || this.title;
        this.baseOsmo = osmoBase || this.baseOsmo;
        this.coverUrl = cover?.smallestUrl || this.coverUrl;
        this.description = description || this.description;
        if (compounds) {
            for (let i = 0; i < compounds.length; i++) {
                const c: Compounds = { name: '', osmolarity: 0 };
                if (compounds[i].name) {
                    c.name = compounds[i].name;
                }
                if (compounds[i].osmolarity) {
                    c.osmolarity = compounds[i].osmolarity;
                }
                this.compounds.push(c);
            }
        }

    }

    static infusions: Ref<Solution[]> = ref([]);
    static additives: Ref<Solution[]> = ref([]);

    static async initInfusion(): Promise<Ref<Solution[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/infusion-solutions?populate=*&pagination[pageSize]=500&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;

        this.infusions.value.splice(0);

        for (let i = 0; i < data.length; i++) {
            const c = new Solution(
                extractLocalizedId(data[i]),
                data[i].attributes.name,
                data[i].attributes.osmolarity,
                (data[i].attributes.cover?.data?.id) ? new ImageMedia({ ...data[i].attributes.cover.data.attributes, id: data[i].attributes.cover.data.id }) : null,
                data[i].attributes.description,
                data[i].attributes.compounds
            );
            this.infusions.value.push(c);
        }

        return this.infusions;
    }

    static async initAdditif(): Promise<Ref<Solution[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/infusion-additives?populate=*&pagination[pageSize]=500&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        this.additives.value.splice(0);

        const data = (await res.json()).data;


        for (let i = 0; i < data.length; i++) {
            const c = new Solution(
                extractLocalizedId(data[i]),
                data[i].attributes.name,
                data[i].attributes.osmolarity,
                (data[i].attributes.cover?.data?.id) ? new ImageMedia({ ...data[i].attributes.cover.data.attributes, id: data[i].attributes.cover.data.id }) : null,
                data[i].attributes.description,
                data[i].attributes.compounds
            );
            this.additives.value.push(c);

        }

        return this.additives;
    }


}
watch(i18n.global.locale, () => {
    if (Solution.infusions.value.length > 0) Solution.initInfusion();
    if (Solution.additives.value.length > 0) Solution.initAdditif();

});

export default Solution;
