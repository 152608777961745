import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row justify-end" }
const _hoisted_3 = { class: "col no-padding bg-grey q-mt-md" }
const _hoisted_4 = {
  class: "row q-mb-md",
  style: {"min-height":"45px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenTitle = _resolveComponent("GreenTitle")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_WizardTab = _resolveComponent("WizardTab")!
  const _component_WizardTable = _resolveComponent("WizardTable")!
  const _component_RequestViewer = _resolveComponent("RequestViewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.editing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_GreenTitle, {
            class: "q-mb-md",
            title: "Dashboard"
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              flat: "",
              "no-caps": "",
              class: "row items-center cursor-pointer text-body2",
              onClick: _cache[0] || (_cache[0] = () => _ctx.refreshPage())
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  size: "1.2em",
                  name: "sync"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Refresh")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_input, {
              borderless: "",
              debounce: "500",
              color: "primary",
              label: _ctx.$t('Header.SecondNavBar.SearchPlaceholder'),
              "label-color": "gray",
              modelValue: _ctx.searchFilter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchFilter) = $event)),
              class: "bg-grey q-mt-lg q-mb-lg q-pl-md q-pr-md"
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "search",
                  color: "primary"
                })
              ]),
              _: 1
            }, 8, ["label", "modelValue"])
          ]),
          _createElementVNode("nav", _hoisted_4, [
            _createVNode(_component_WizardTab, {
              title: _ctx.$t('Compatibility.CompatibilityRequest.Wizard.NewRequest'),
              notifications: _ctx.requests.filter((request) => ['accepted'].includes(request.status)).length,
              id: "0",
              callback: _ctx.changeTab,
              current: _ctx.currentTab
            }, null, 8, ["title", "notifications", "callback", "current"]),
            _createVNode(_component_WizardTab, {
              title: _ctx.$t('Compatibility.CompatibilityRequest.Wizard.Clarification'),
              notifications: _ctx.requests.filter((request) => ['ready', 'proposed'].includes(request.status)).length,
              id: "1",
              callback: _ctx.changeTab,
              current: _ctx.currentTab
            }, null, 8, ["title", "notifications", "callback", "current"]),
            _createVNode(_component_WizardTab, {
              title: _ctx.$t('Compatibility.CompatibilityRequest.Wizard.TestPlan'),
              notifications: _ctx.requests.filter((request) => ['scheduled', 'completed'].includes(request.status)).length,
              id: "2",
              callback: _ctx.changeTab,
              current: _ctx.currentTab
            }, null, 8, ["title", "notifications", "callback", "current"])
          ]),
          _createVNode(_component_WizardTable, {
            requests: _ctx.filteredTabRequests,
            currentTab: _ctx.currentTab,
            openRequest: _ctx.openRequest,
            searching: _ctx.searchFilter.length
          }, null, 8, ["requests", "currentTab", "openRequest", "searching"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.editing)
      ? (_openBlock(), _createBlock(_component_RequestViewer, {
          key: 1,
          request: _ctx.requests.find((r) => r.id === _ctx.editing),
          tab: _ctx.currentTab,
          goBack: async() => {await _ctx.updateRequests(); _ctx.openRequest(null);}
        }, null, 8, ["request", "tab", "goBack"]))
      : _createCommentVNode("", true)
  ], 64))
}