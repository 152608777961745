

import { defineComponent } from 'vue';

interface MainContentData {
}

export default defineComponent({
    props: [],

    data(): MainContentData {
        return {};
    }
});
