import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9aa0565c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "column",
  style: {"gap":"8px"}
}
const _hoisted_2 = {
  key: 0,
  class: "bg-primary q-pa-md text-white"
}
const _hoisted_3 = {
  key: 1,
  class: "bg-negative q-pa-md text-white"
}
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col no-padding bg-grey" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col no-padding bg-grey" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.registered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('Login.LoginSection.ConfirmationSent')), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Login.LoginSection.EmailUsername")), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_q_input, {
          dense: "",
          borderless: "",
          modelValue: _ctx.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
          debounce: "500",
          color: "green",
          class: "col q-pl-sm",
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.loginFunction()), ["enter"]))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("Login.LoginSection.Password")), 1),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_q_input, {
        dense: "",
        borderless: "",
        type: "password",
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
        debounce: "500",
        color: "green",
        class: "col q-pl-sm",
        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.loginFunction()), ["enter"]))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", {
      class: "row text-primary justify-between forgot-btn",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleShowForgot && _ctx.toggleShowForgot(...args)))
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("Login.LoginSection.ForgotPassword")) + "?", 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_q_btn, {
        type: "submit",
        style: {"width":"100%"},
        class: "bg-secondary text-white",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loginFunction())),
        to: "home"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Login.LoginSection.RegisterNow")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleShowRegister && _ctx.toggleShowRegister(...args))),
        class: "back-btn"
      }, [
        _createVNode(_component_q_icon, {
          class: "material-icons-round bg-secondary text-white",
          name: "chevron_right",
          style: {"font-size":"1em","border-radius":"25px"}
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("Login.LoginSection.ToRegister")), 1)
      ])
    ])
  ]))
}