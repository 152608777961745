
import { defineComponent, Ref, ref } from 'vue';
import MainContent from '@/components/layout/main_content.vue';
import { logUserOut } from '@/utils/auth';
import { bcTab } from '@/utils/breadcrumbs';
import SideMenu from './sideMenu.vue';
import QBtn from 'quasar/src/components/btn/QBtn.js';import QIcon from 'quasar/src/components/icon/QIcon.js';import QInput from 'quasar/src/components/input/QInput.js';import QImg from 'quasar/src/components/img/QImg.js';;

interface dataI {
  logo: Ref<String>;
  username: Ref<String | null>;
  myUsername: Ref<any>;
  isReviewer: Ref<Boolean>;
  search: Ref<string>;
  hide: Ref<Boolean>;
  showMenu: Ref<Boolean>;
  showSearchfield: Ref<Boolean>;
}

interface breadcrumbsI {
  title: string;
  path: string;
}

export default defineComponent({
    components: {
        MainContent,
        SideMenu,
        QBtn,
        QIcon,
        QInput,
        QImg
    },
    props: ['title', 'subtitle', 'breadCrumbsTab', 'breadCrumbsPaths'],
    setup(): dataI {
        return {
            logo: ref('@/assets/step_1_done.png'),
            username: ref(''),
            myUsername: ref(window.localStorage.getItem('username')),
            isReviewer: ref(window.localStorage.getItem('reviewer') === 'true'),
            search: ref(''),
            hide: ref(false),
            showMenu: ref(false),
            showSearchfield: ref(false)
        };
    },
    methods: {
        async reloadPage(s: string) {
            await this.$router.push(s);
            location.reload();
        },
        changeLanguage(s: string) {
            this.$i18n.locale = s;
            sessionStorage.setItem('locale', s);
        },
        logOut() {
            logUserOut();
            this.$router.push('/login');
        },
        goToSearchpage() {
            this.$router.push({
                path: '/search',
                query: {
                    search: this.search
                }
            });
            this.search = '';
        }
    },

    computed: {
        UN() {
            return window.localStorage.getItem('username');
        },
        bcTabUpdated(): Ref<breadcrumbsI[]> {
            return bcTab;
        },
        FirstTitle(): string {
            if (bcTab?.value[0]) {
                return bcTab?.value[0]?.title;
            } else {
                return '';
            }
        }
    }
});
