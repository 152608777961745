<template>
    <q-dialog v-model="show" @hide="() => {closeModal()}">
        <q-card class="bg-grey main-card q-px-lg">
            <q-card-section>
                <div>
                    <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{{$t('Requester.RejectionReason')}}</div>
                    <div class="row">
                        <div class="col-3 subtitle-thick" />
                        <div class="col-9 subtitle-thin" />
                    </div>
                </div>

                <q-input
                    v-model="text" type="textarea" debounce="100" rows="10" outlined bg-color="white"
                    class="q-mt-xl text-input" borderless
                />
            </q-card-section>

            <q-card-section class="q-px-md q-mb-md">
                <div class="row justify-end">
                    <div class="row justify-end col-6">

                        <!-- Close button -->
                        <q-btn
                            no-caps dense unelevated @click="() => closeModal()"
                            class="col text-bold q-py-xs bg-white q-mr-sm outline-primary">
                            {{ $t('Requester.Close') }}
                        </q-btn>

                        <!-- Reject button -->
                        <q-btn
                            no-caps dense unelevated @click="() => callback(text)"
                            class="col text-white text-bold q-py-xs bg-primary q-mr-sm outline-primary">
                            {{ $t('Requester.Send') }}
                        </q-btn>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: ['showModal', 'closeModal', 'callback'],
    setup(props) {
        return {
            text: ref(''),
            show: ref(false)
        };
    },
    components: {
    },
    watch: {
        showModal(value) {
            this.show = value;
        }
    }
});

</script>

<style scoped lang="sass">
@import '@/styles/quasar.variables.scss'

.green-title
    font-size: 16px
    color: $primary

.main-card
    max-width: 60vw !important
    width: 100% !important
    border-radius: 20px !important
    border: 1px solid #666 !important

.subtitle-thick
    border-bottom: 5px solid $primary

.subtitle-thin
    border-bottom: 3px solid $primary

</style>

