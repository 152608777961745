
import { defineComponent, Ref, ref } from 'vue';
import { searchInText } from '@/utils/search';

import GreenTitle from '@/components/shared/green_title.vue';
import WizardTab from '../shared/wizard_tab.vue';
import WizardTable from './editor_wizard_table.vue';
import RequestViewer from './editor_request_viewer.vue';

import CompatibilityRequest from '@/model/compatibility-request';
import CompatibilityPlan from '@/model/compatibility-plan';

interface dataI {
    currentTab: Ref<number>,
    editing: Ref<string|null>,
    searchFilter: Ref<string>
};
export default defineComponent({
    props: ['requests', 'updateRequests'],
    setup(): dataI {
        return {
            currentTab: ref(0),
            editing: ref(null),
            searchFilter: ref('')
        };
    },
    components: {
        WizardTab,
        WizardTable,
        GreenTitle,
        RequestViewer
    },
    computed: {
        tabRequests(): CompatibilityRequest[] | CompatibilityPlan[] {
            const index = Number(this.currentTab);

            return index === 0
                ? this.requests.requests
                : this.requests.plans.filter((plan: CompatibilityPlan) => ['accepted', 'ready', 'proposed', 'scheduled', 'completed'].includes(plan.status));
        },
        filteredTabRequests(): CompatibilityRequest[] | CompatibilityPlan[] {
            const index = Number(this.currentTab);
            const filtered = index === 0 ? this.tabRequests : (this.tabRequests as CompatibilityPlan[]).filter((request: CompatibilityPlan) => searchInText(request.compatibility.medicin.name, this.searchFilter));

            if (filtered.length > 0) {
                return filtered;
            } else {
                return this.tabRequests;
            }
        }
    },
    methods: {
        async refreshPage() {
            await this.updateRequests();
        },
        changeTab(n: number) {
            this.currentTab = n;
        },
        openRequest(req: CompatibilityRequest | CompatibilityPlan | null) {
            if (req && req.id) {
                this.editing = req.id;
            } else {
                this.editing = null;
            }
        }
    }
});
