
import { defineComponent, Ref, ref } from 'vue';
import CompatibilityRequest from '@/model/compatibility-request';
import { IUser } from '@/model/helper/interface';
import GreenTitle from '@/components/shared/green_title.vue';

// Import Tragerlosung from '@/model/tragerlosung';

interface dataI {
  newRequest: Ref<CompatibilityRequest>;
  currentUser: Ref<IUser|undefined>;
  inputName: Ref<string>;
  inputAgent: Ref<string>;
  errorMessage: Ref<string>;
  errorMessageSend: Ref<string>;
  validationMessage: Ref<string>;
}

export default defineComponent({
    props: ['transport', 'closeForm', 'perfusionWay'],
    setup(props): dataI {
        return {
            newRequest: ref<CompatibilityRequest>(CompatibilityRequest.from({
                id: null,
                institute: '',
                firstName: '',
                lastName: '',
                email: '',
                position: '',
                comment: '',
                status: 'new',
                transportSolution: props.transport,
                infusionType: (props.perfusionWay === 'leit' ? 'coinfusion' : 'container'),
                requester: {} as IUser,
                reviewer: {} as IUser,
                lang: 'de',
                address: {
                    street: '',
                    postalCode: '',
                    city: ''
                },
                medicins: []
            })),
            currentUser: ref(),
            inputName: ref(''),
            inputAgent: ref(''),
            errorMessage: ref(''),
            errorMessageSend: ref(''),
            validationMessage: ref('')
        };
    },
    components: {
        GreenTitle
    },
    async beforeCreate() {
        const res = await fetch(process.env.VUE_APP_API_URL + '/users/me', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
            }
        });
        const json = await res.json();

        this.newRequest.requester = json;
        this.newRequest.email = json.email || '';
        this.newRequest.institute = json.institution || '';
        this.newRequest.firstName = json.firstName || '';
        this.newRequest.lastName = json.lastName || '';
        this.newRequest.address.street = json.street || '';
        this.newRequest.address.postalCode = json.postalCode || '';
        this.newRequest.address.city = json.city || '';

        // @ts-ignore
        console.log(this.$parent.$parent.$parent.$parent.setHideElements(true));

        // @ts-ignore
        console.log(this.$parent.$parent.setHideElements(true));
    },
    methods: {
        addInput() {
            if (this.inputName && this.inputAgent) {
                this.errorMessage = '';
                this.newRequest.medicins.push({
                    name: this.inputName,
                    nameLong: '',
                    activeAgent: this.inputAgent
                });
                this.inputName = '';
                this.inputAgent = '';
            } else {
                this.errorMessage = 'Bitte füllen Sie alle Felder aus!';
            }
        },
        removeInput(index: number) {
            this.newRequest.medicins.splice(index, 1);
        },
        closeRequestForm() {

            // @ts-ignore
            this.$parent.$parent.$parent.$parent.setHideElements(false);

            // @ts-ignore
            this.$parent.$parent.setHideElements(false);

            this.closeForm();
        },
        async showRequest() {
            if (this.newRequest.medicins.length < 1) {
                this.errorMessageSend = 'Compatibility.CompatibilityRequest.EmptyMedsList';
                return;
            }
            await this.newRequest.save();
            this.validationMessage = 'Compatibility.CompatibilityRequest.Created';
            window.scrollTo(0, 0);
        }
    },
    beforeUnmounted() {
        this.closeRequestForm();
    }
});

