import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row items-center",
  style: {"height":"350px"}
}
const _hoisted_2 = { class: "col-2 right" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-2 left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        round: "",
        flat: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prev()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_icon, {
            class: "text-secondary",
            name: "arrow_back_ios"
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cNews, (n) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col q-ma-sm",
            key: n.id
          }, [
            _createVNode(_component_Card, {
              title: n.title,
              desc: n.description,
              path: ('/news/' + n.id),
              selectable: false,
              url: _ctx.baseApi + n.banner
            }, null, 8, ["title", "desc", "path", "url"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_q_btn, {
        round: "",
        flat: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_icon, {
            class: "text-secondary",
            name: "arrow_forward_ios"
          })
        ]),
        _: 1
      })
    ])
  ]))
}