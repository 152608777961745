import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import de from '@/locales/de.json';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): Record<string, any> {
    // Const const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const locales:Record<string, any> = {
        de, en, fr
    };

    return locales;
}

export default createI18n({
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            }
        },
        fr: {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            }
        },
        de: {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            }
        }
    },
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages: loadLocaleMessages()
});
