import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-py-md" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "scopesub-445877" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "q-py-md"
}
const _hoisted_6 = { class: "gt-sm" }
const _hoisted_7 = { class: "row q-pa-sm" }
const _hoisted_8 = { class: "col-4 ellipsis text-bold text-grey-6" }
const _hoisted_9 = { class: "col ellipsis text-bold text-grey-6" }
const _hoisted_10 = { class: "col ellipsis text-bold text-grey-6" }
const _hoisted_11 = { class: "col ellipsis text-bold text-grey-6 row justify-end" }
const _hoisted_12 = { class: "row q-pa-sm" }
const _hoisted_13 = { class: "col-4 ellipsis text-primary" }
const _hoisted_14 = { class: "col ellipsis" }
const _hoisted_15 = { class: "col ellipsis" }
const _hoisted_16 = { class: "col ellipsis row justify-end" }
const _hoisted_17 = { class: "q-mt-md lt-md" }
const _hoisted_18 = { class: "row q-pa-sm" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "ellipsis text-primary text-bold" }
const _hoisted_21 = { class: "ellipsis text-grey-7" }
const _hoisted_22 = { class: "ellipsis row no-wrap text-grey-7" }
const _hoisted_23 = { class: "col-2 column justify-center items-end q-px-xs" }
const _hoisted_24 = { class: "ellipsis" }
const _hoisted_25 = {
  key: 1,
  class: "q-py-md"
}
const _hoisted_26 = { class: "documents" }
const _hoisted_27 = ["href"]
const _hoisted_28 = {
  key: 2,
  class: "q-py-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenTitle = _resolveComponent("GreenTitle")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_MainContent = _resolveComponent("MainContent")!

  return (_openBlock(), _createBlock(_component_MainContent, null, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createVNode(_component_GreenTitle, {
          title: _ctx.currentProduct?.name
        }, null, 8, ["title"]),
        _createElementVNode("p", {
          class: "q-pt-md q-px-sm text-pre-wrap",
          innerHTML: _ctx.compileMarkDown(_ctx.currentProduct?.description ?? '')
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("article", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProduct?.informations, (info, index) => {
          return (_openBlock(), _createElementBlock("section", {
            class: "q-py-md informations",
            key: index
          }, [
            _createVNode(_component_q_expansion_item, {
              "expand-icon": "add_circle",
              "expanded-icon": "remove_circle",
              "expand-icon-class": "text-secondary",
              color: "blue",
              group: "somegroup",
              label: info.title,
              class: "col-12"
            }, {
              header: _withCtx(() => [
                _createVNode(_component_GreenTitle, {
                  class: "flex-1",
                  title: info.title
                }, null, 8, ["title"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "q-pt-md q-px-sm text-pre-wrap",
                  innerHTML: _ctx.compileMarkDown(info.content ?? '')
                }, null, 8, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["label"])
          ]))
        }), 128)),
        (_ctx.currentProduct?.articles && _ctx.currentProduct?.articles?.length > 0)
          ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
              _createVNode(_component_GreenTitle, {
                title: _ctx.$t('ProductDetails.Articles')
              }, null, 8, ["title"]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('ProductDetails.Article.Description')), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('ProductDetails.Article.Reference')), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('ProductDetails.Article.Pharmacode')), 1),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('ProductDetails.Article.Quantity')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProduct?.articles, (art, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(art.description || '-'), 1),
                      _createElementVNode("div", _hoisted_14, _toDisplayString(art.reference || '-'), 1),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(art.pharmacode || '-'), 1),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(art.quantity || 0) + " " + _toDisplayString(_ctx.$t('ProductDetails.Article.QuantityUnit')), 1)
                    ]),
                    _createVNode(_component_q_separator)
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_17, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProduct?.articles, (art, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, _toDisplayString(art.description || '-'), 1),
                        _createElementVNode("div", _hoisted_21, "Reference: " + _toDisplayString(art.reference || '-'), 1),
                        _createElementVNode("div", _hoisted_22, "Pharmacode: " + _toDisplayString(art.pharmacode || '-'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, _toDisplayString(art.quantity || 0) + " " + _toDisplayString(_ctx.$t('ProductDetails.Article.QuantityUnit')), 1)
                      ])
                    ]),
                    _createVNode(_component_q_separator)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentProduct?.documents && _ctx.currentProduct?.documents?.length > 0)
          ? (_openBlock(), _createElementBlock("section", _hoisted_25, [
              _createVNode(_component_GreenTitle, {
                title: _ctx.$t('ProductDetails.Documents')
              }, null, 8, ["title"]),
              _createElementVNode("ul", _hoisted_26, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProduct?.documents, (doc, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createElementVNode("a", {
                      class: "q-py-md",
                      href: (_ctx.baseApi || '') + doc?.document?.url
                    }, [
                      _createTextVNode(_toDisplayString(doc.name) + " ", 1),
                      _createVNode(_component_q_icon, { name: "insert_drive_file" })
                    ], 8, _hoisted_27)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        ((_ctx.currentProduct?.faqs && _ctx.currentProduct?.faqs.length > 0) || (_ctx.currentProduct?.faqsCategories && _ctx.currentProduct?.faqsCategories.length > 0))
          ? (_openBlock(), _createElementBlock("section", _hoisted_28, [
              _createVNode(_component_GreenTitle, {
                title: _ctx.$t('ProductDetails.FAQs')
              }, null, 8, ["title"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentSections, (section) => {
                return (_openBlock(), _createBlock(_component_q_list, {
                  key: section.id,
                  bordered: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      "expand-icon": "add_circle",
                      "expanded-icon": "remove_circle",
                      "expand-icon-class": "text-secondary",
                      color: "blue",
                      group: "somegroup",
                      label: section.title,
                      "header-class": "text-primary"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(section.description), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["label"]),
                    _createVNode(_component_q_separator)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}