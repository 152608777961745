
import { defineComponent } from 'vue';

import { logUserOut } from '@/utils/auth';

interface dataI {
}
export default defineComponent({
    props: {
        showMenu: {
            type: Boolean,
            default: false
        },
        reloadPage: {
            type: Function,
            default: () => {

            }
        }
    },
    setup(): dataI {
        return {
        };
    },
    watch: {
        '$q.screen.width'(val) {
            if (this.showMenu && val >= 1024) {
                this.closeMenu();
            }
        }
    },
    methods: {
        closeMenu() {
            this.$emit('closeMenu');
        },
        changeLanguage(s: string) {
            this.$i18n.locale = s;
            sessionStorage.setItem('locale', s);
        },
        logout() {
            logUserOut();
            this.closeMenu();
            this.$router.push('/login');
        }
    },
    beforeMount() {
        window.addEventListener('scroll', this.closeMenu);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.closeMenu);
    }
});
