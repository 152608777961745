import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["col flex justify-center items-center q-mr-xs q-ml-xs", _ctx.id == _ctx.current ? 'selected' : 'unselected'])
  }, [
    _createVNode(_component_q_btn, {
      "no-caps": "",
      flat: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.callback(_ctx.id))),
      style: {"width":"100%","height":"100%"}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title) + " " + _toDisplayString(_ctx.notifications > 0 ? '(' + _ctx.notifications + ')' : ''), 1)
      ]),
      _: 1
    })
  ], 2))
}