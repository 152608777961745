
import News from '@/model/news';
import { defineComponent, Ref, ref } from 'vue';
import Card from '@/components/shared/card.vue';
import Screen from 'quasar/src/plugins/Screen.js';;

interface dataI {
    indexTable: Ref<number>;
}

export default defineComponent({
    components: {
        Card
    },
    props: ['news'],
    setup(props): dataI {
        return {
            indexTable: ref(0)
        };
    },
    methods: {
        prev() {
            const tableLength = Screen.lt.sm ? 1 : 3;

            if (this.indexTable > 0) {
                this.indexTable--;
            } else {
                this.indexTable = this.news.length - tableLength;
            }
        },
        next() {
            const tableLength = Screen.lt.sm ? 1 : 3;

            if (this.indexTable + tableLength < this.news.length) {
                this.indexTable++;
            } else {
                this.indexTable = 0;
            }
        }
    },
    computed: {
        cNews(): News[] {
            const tableLength = Screen.lt.sm ? 1 : 3;

            if (this.indexTable > 0 && this.indexTable === this.news.length - 1 && (Screen.gt.sm || Screen.sm)) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.indexTable = this.indexTable - 3;
            }
            return this.news.slice(this.indexTable, this.indexTable + tableLength);
        },
        baseApi(): string | undefined {
            return process.env.VUE_APP_API_URL?.replace('/api', '');
        }
    }
});
