import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BbHeader = _resolveComponent("BbHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BbFooter = _resolveComponent("BbFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createVNode(_component_BbHeader, {
        title: _ctx.title,
        subtitle: _ctx.subtitle,
        breadCrumbsTab: _ctx.breadCrumbsTab,
        breadCrumbsPaths: _ctx.breadCrumbsPaths
      }, null, 8, ["title", "subtitle", "breadCrumbsTab", "breadCrumbsPaths"])
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view, { onUpdateTitles: _ctx.updateTitles }, null, 8, ["onUpdateTitles"])
    ]),
    _createElementVNode("footer", null, [
      _createVNode(_component_BbFooter)
    ])
  ], 64))
}