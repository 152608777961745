
import MainContent from '@/components/layout/main_content.vue';
import BigCard from '@/components/shared/big_card.vue';
import GreenTitle from '@/components/shared/green_title.vue';

import RegisterForm from '@/components/login/register_form.vue';
import LoginForm from '@/components/login/login_form.vue';
import ForgotForm from '@/components/login/forgot_form.vue';

import { setBreadcrumbs } from '@/utils/breadcrumbs';

import { defineComponent, ref, Ref } from 'vue';

interface dataI {
  id: Ref<string>;
  pwd: Ref<string>;
  jwt: Ref<string>;
  error: Ref<string>;
  showRegister: Ref<boolean>;
  showForgot: Ref<boolean>;
  registered: Ref<boolean>;
  mailSent: Ref<boolean>;
}
export default defineComponent({
    components: {
        GreenTitle,
        BigCard,
        MainContent,
        RegisterForm,
        LoginForm,
        ForgotForm
    },
    setup(): dataI {
        return {
            id: ref(''),
            pwd: ref('pwd'),
            jwt: ref(''),
            error: ref(''),
            showRegister: ref(false),
            showForgot: ref(false),
            registered: ref(false),
            mailSent: ref(false)
        };
    },
    computed: {
        headerTitle(): string {
            return this.showRegister ? this.$t('Login.LoginSection.ToRegister') : this.showForgot ? this.$t('Login.LoginSection.ForgotPassword') : this.$t('Login.LoginSection.Title');
        }
    },
    methods: {
        updateSubNavBar() {
            setBreadcrumbs([{ title: 'hide', path: '' }]);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Home.Title', 'Home.Subtitle');
            }
        },
        toggleShowRegister() {
            this.showRegister = !this.showRegister;
        },
        toggleShowForgot() {
            this.showForgot = !this.showForgot;
        },
        setRegistered() {
            this.registered = true;
            this.toggleShowRegister();
        },
        setMailSent() {
            this.mailSent = true;
        }
    },
    mounted() {
        this.updateSubNavBar();
    }
});
