
import CompatibilityPlan from '@/model/compatibility-plan';
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['requests', 'currentTab', 'openRequest', 'searching'],
    data: function() {
        return {
            requestsL: this.requests
        };
    },
    methods: {
        formatedDate(date: string) {
            if (!date) {
                return '';
            }
            const requestDate = new Date(date).toLocaleString(this.$i18n.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
            return requestDate;
        }
    },
    computed: {
        currentTabRequests(): CompatibilityPlan[] {
            const currentStatus = Number(this.currentTab) === 0
                ? ['accepted']
                : Number(this.currentTab) === 1 ? ['ready', 'proposed'] : ['scheduled', 'completed'];

            return this.requests.filter((req: CompatibilityPlan) => currentStatus.includes(req.status));
        },
        otherTabRequests(): CompatibilityPlan[] {
            const currentStatus = Number(this.currentTab) === 0
                ? ['accepted']
                : Number(this.currentTab) === 1 ? ['ready', 'proposed'] : ['scheduled', 'completed'];

            return this.requests.filter((req: CompatibilityPlan) => !currentStatus.includes(req.status));
        }
    }
});
