import router from '@/router';
import jwtDecode from 'jwt-decode';
import { Ref, ref } from 'vue';
import { LocationQueryValue } from 'vue-router';

let myJwt: string;
const validAuth: Ref<boolean> = ref(false);

// Let myUsername: string = 'AAA';

const myUsername: Ref<string> = ref('');
const currentJwt: Ref<string> = ref('');
const currentReviewer: Ref<boolean> = ref(false);

function logUserOut() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('reviewer');
    localStorage.removeItem('username');
    validAuth.value = false;
};

const deferred: any = {};
const authReady = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
});


async function login(id: string, pwd: string, reviewer: boolean) {
    try {
        const res = await fetch(process.env.VUE_APP_API_URL + (reviewer ? '/auth/local-reviewer' : '/auth/local'), {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                identifier: id,
                password: pwd
            })
        });
        const data = await res.json();
        const { jwt } = data;
        const { user } = data;
        myJwt = jwt;
        currentReviewer.value = reviewer;
        myUsername.value = ref(user.username);
        currentJwt.value = ref(jwt);
        window.localStorage.setItem('jwt', jwt);
        window.localStorage.setItem('reviewer', String(reviewer));
        window.localStorage.setItem('username', user.username);

        const decoded: any = jwtDecode(jwt);
        deferred.resolve();
        console.log('login status ' + res.status);
        await router.push(reviewer ? '/requests' : 'home');
    } catch (error) {
        console.log('login ERROR');
    }
}

async function checkCurrentAuth() {
    try {

        const jwtFromStorage: any = window.localStorage.getItem('jwt');
        if (window.localStorage.getItem('jwt') === null) {
            validAuth.value = false;
            console.log('jwt is null');
            return;

        }
        if (window.localStorage.getItem('reviewer')) {
            currentReviewer.value = (window.localStorage.getItem('reviewer') === 'true');
        }

        const decoded: any = jwtDecode(jwtFromStorage);

        const res: Response = await fetch(process.env.VUE_APP_API_URL + (currentReviewer.value ? '/user-local' : '/users') + '/me', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
            }

        });

        if (res.status >= 400) {
            localStorage.removeItem('jwt');
            validAuth.value = false;
            router.push('login');
        } else {
            currentJwt.value = window.localStorage.getItem('jwt') || '';
            deferred.resolve();
            validAuth.value = true;
        }

    } catch (error) {
        console.log('tryToConnectToServer ERROR', error);
        validAuth.value = false;
    }

}

async function getMe() {
    try {
        const res = await fetch(process.env.VUE_APP_API_URL + (currentReviewer.value ? '/user-local' : '/users') + '/me', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
            }
        });
        const data = (await res.json());

        return data;
    } catch (e) {

    }
}

export {
    validAuth,
    login,
    logUserOut,
    myUsername,
    currentJwt,
    checkCurrentAuth,
    authReady,
    getMe
};

