import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { ImageMedia } from './helper/image';
import { watch, Ref, ref } from 'vue';

interface Block {
    text: string,
    image: string
}

export class HomePanels {

    id: string;
    title: string = '';
    description: string = '';
    thumbnail: string = '';
    banner: string = '';
    blocks: Block[] = [];
    order: number = 0;
    static panels: Ref<HomePanels[]> = ref([]);

    constructor(id: string | number, order: number, title?: string, description?: string, thumbnail?: string, banner?: string) {
        this.id = String(id);
        this.order = order;
        if (title) {
            this.title = title;
        }
        if (description) {
            this.description = description;
        }
        if (thumbnail) {
            this.thumbnail = thumbnail;
        }
        if (banner) {
            this.banner = banner;
        }
    }

    static async init(): Promise<Ref<HomePanels[]>> {
        await authReady;

        this.panels.value = [];

        const res = await fetch(process.env.VUE_APP_API_URL + '/home-panels?populate=*&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;
        for (let i = 0; i < data.length; i++) {
            const image = new ImageMedia({ ...data[i].attributes.image.data.attributes, id: data[i].attributes.image.data.id });
            const c = new HomePanels(
                data[i].id,
                data[i].attributes.order,
                data[i].attributes.title,
                data[i].attributes.description,
                image.smallestUrl,
                image.largestUrl
            );
            this.panels.value.push(c);
        }

        // Sort by in-strapi defined order
        this.panels.value.sort((a, b) => {
            return a.order - b.order;
        });
        return this.panels;
    }

}
watch(i18n.global.locale, () => {
    if (HomePanels.panels.value.length > 0) HomePanels.init();
});

export default HomePanels;
