import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Faq from '@/views/Faq.vue';
import ProduktCatalog from '@/views/ProduktCatalog.vue';
import ProduktDetails from '@/views/ProduktDetails.vue';
import Kompat from '@/views/Kompat/kompat.vue';
import Osmo from '@/views/Osmo/Osmo.vue';
import Requests from '@/views/Requests/Requests.vue';
import News from '@/views/News.vue';
import Login from '@/views/Login.vue';
import Privacy from '@/views/Privacy.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Search from '@/views/Search.vue';


import { checkCurrentAuth, validAuth } from '@/utils/auth';

import i18n from '@/i18n';

const routes: Array<RouteRecordRaw> = [

    // MAIN VIEWS
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/faq',
        component: Faq
    },

    {
        path: '/product',
        name: 'product',
        component: ProduktCatalog
    },

    {
        path: '/product/:id',
        component: ProduktDetails
    },
    {
        path: '/kompat',
        component: Kompat
    },
    {
        path: '/osmo',
        component: Osmo
    },
    {
        path: '/news/:id',
        name: 'news',
        component: News
    },
    {
        path: '/requests',
        name: 'requests',
        component: Requests
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/:default(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});


router.beforeEach(async(to, from, next) => {
    console.log(i18n.global.locale);
    i18n.global.locale.value = window.sessionStorage.getItem('locale') || 'de';
    await checkCurrentAuth();


    if (validAuth.value === true) {
        if (to.path !== '/requests' && window.localStorage.getItem('reviewer') === 'true') {
            return next('/requests');
        }
        if (to.path === '/requests' && window.localStorage.getItem('reviewer') === 'false') {
            return next('/home');
        }
        return next();
    }

    if (to.path === '/login' || to.path === '/reset-password' || to.path === '/privacy') {
        return next();
    }
    return next('/login');

});

export default router;
