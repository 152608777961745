<template>
    <div>

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.FirstName'), request.firstName], [$t('Compatibility.CompatibilityRequest.LastName'), request.lastName]]" />

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.Street'), request.address.street]]" />

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.PLZ'), request.address.postalCode], [$t('Compatibility.CompatibilityRequest.Location'), request.address.city]]" />

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.PositionOrFunction'), request.position]]" />

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.EmailAddress'), request.email]]" />

        <AnfragerLine :items="[[$t('Compatibility.CompatibilityRequest.Comment'), request.comment]]" />

    </div>
</template>

<script>
import { defineComponent } from 'vue';

const AnfragerLine = {
    props: ['items'],
    render: function() {
        const titles = [];
        const values = [];

        this.items.forEach(item => {
            titles.push(
                <div class="text-primary q-ma-sm col justify-start">{ item[0] }</div>
            );
            values.push(
                <div class="text-dark text-bold q-ma-sm col justify-start q-ml-md">{ item[1] }</div>
            );
        });
        return (<div><div class="q-my-md q-mx-sm">
            <div class="row justify-between">{ titles }</div>
            <div class="row justify-between">{ values }</div>
        </div></div>);
    }
};

export default defineComponent({
    props: ['request'],
    setup() {

    },
    components: {
        AnfragerLine
    }
});
</script>
