<template>
    <div>
        <div v-if="!validated">
            <Subtitle :title="$t('Requests.UploadTestReport')" />
            <div class="col q-mr-sm">
                <!-- <div class="text-primary q-ma-sm">{{ $t('Requests.ChooseFile') }}</div> -->
                <div class="row no-wrap flex items-center q-mt-lg">
                    <div class="col-9 q-pr-sm">
                        <q-file class="col-3" :label="$t('Requests.ChooseFile')" borderless filled multiple append dense v-model="model" />
                    </div>
                    <div class="col-3 PurpleToWhite q-px-lg ellipsis">
                        <q-btn no-caps flat @click="() => {showFiles()}" style="width: 100%; height: 40px" class="text-subtitle">
                            {{ $t('Send') }}
                        </q-btn>
                    </div>
                </div>
            </div>

            <div class="q-pa-xs">
                <div class="row q-pa-md q-mt-sm items-center text-primary">
                    <div class="col-7 ellipsis">{{ $t("Requests.DocumentName") }}</div>
                    <div class="col-2 ellipsis">{{ $t("Requests.CreationDate") }}</div>
                    <div class="col-2 ellipsis">{{ $t("Requests.DocumentSize") }}</div>
                    <div class="col-1 ellipsis"></div>
                </div>
                <div class="row q-pa-md q-mt-sm items-center underlined" v-for="file in files" :key="file.name">
                    <div class="col-7 ellipsis">{{ file.name }}</div>
                    <div class="col-2 ellipsis">{{ formatedDate(file.lastModified) }}</div>
                    <div class="col-2 ellipsis">{{ file.size ? file.size / 1000 : 0 }}KB</div>
                    <div class="col-1 ellipsis text-h6 text-secondary row justify-end"><q-icon name="delete" class="cursor-pointer"/></div>
                </div>
            </div>

            <div class="row justify-end" style="gap: 10px; margin-top: 40px">
                <div class="PurpleToWhite q-px-lg q-mt-lg">
                    <q-btn no-caps flat @click="() => {addMediaToPlan();}" style="width: 100%" class="text-bold text-subtitle text-subtitle1">
                        {{ $t("Requests.SaveDraft") }}
                    </q-btn>
                </div>
                <div class="PurpleToWhite q-px-lg q-mt-lg">
                    <q-btn no-caps flat @click="() => {validated = true; addMediaToPlan(); }" style="width: 100%" class="text-bold text-subtitle text-subtitle1">
                        {{ $t("Requests.AddCompatibility") }}
                    </q-btn>
                </div>
            </div>
        </div>

        <div v-if="validated">
            <Subtitle :title="$t('Requests.DateVersion')" />
            <div class="row justify-between q-mb-lg">
                <div class="col q-mr-sm">
                    <div class="text-primary q-ma-sm">{{$t("Requests.PublicationDate")}}</div>
                    <q-input filled v-model="date" mask="date" :rules="['date']">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="date">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Close" color="primary" flat></q-btn>
                            </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                    </q-input>
                </div>
                <div class="col q-ml-sm">
                    <div class="text-primary q-ma-sm">Version</div>
                    <div class="bg-grey text-dark flex items-center text-subtitle2 q-ml-sm q-px-md" style="height: 55px">Version {{ request.revisions.length }}</div>
                </div>
            </div>
            <div class="row justify-end">
                <div class="PurpleToWhite">
                    <q-btn no-caps flat @click="validatePlan()" style="width: 100%" class="text-bold text-h6">
                        {{$t("Requests.PublishCompatibility")}}
                    </q-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import Media from '@/model/helper/media';

const Subtitle = {
    props: ['title'],
    render() {
        return (<div>
            <div class="q-ma-sm q-mt-md text-primary" style="font-size: 20px">{this.title}</div>
            <div class="row">
                <div class="col-3 subtitle-thick" />
                <div class="col-9 subtitle-thin" />
            </div>
        </div>);
    }
};

export default defineComponent({
    props: ['request', 'goBack'],
    data: function() {
        return {
            model: null,
            date: ref(this.getCurrentDate()),
            files: ref(null),
            validated: ref(false),
            fileDatas: ref([]),
            requestU: this.request
        };
    },
    components: {
        Subtitle
    },
    methods: {
        showFiles() {
            this.files = this.model;
            this.model = [];
            if (this.files?.length) {
                this.uploadFiles();
            }
        },
        async validatePlan() {
            this.requestU.status = 'completed';
            await this.requestU.save(['status']);
            this.goBack();
        },
        async addMediaToPlan() {
            this.requestU.testReports = this.fileDatas;
            await this.requestU.save(['testReports']);
        },
        async uploadFiles() {
            for (const file of this.files) {
                const media = await Media.upload(file);

                this.fileDatas.push(media);
            };
        },
        getCurrentDate() {
            const date = new Date();
            return date.getFullYear() + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getDate()).padStart(2, '0');
        },
        formatedDate(timestamp) {
            const date = new Date(timestamp);

            return date?.toLocaleDateString(this.$i18n.locale, {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                month: 'long'
            });
        }
    }
});
</script>

<style lang="sass" scoped>
@import '../../../styles/quasar.variables.scss'

.underlined
    border-bottom: 1px solid $accent
</style>
