import { Media, StrapiMediaFormat, StrapiDisplayMedia } from './media';


export class ImageMedia extends Media {

    private media: StrapiDisplayMedia;

    constructor(
        raw: StrapiDisplayMedia
    ) {
        super(
            raw.id || '',
            raw.name,
            raw.hash,
            raw.ext,
            raw.mime,
            raw.size,
            raw.url,
            raw.created_at,
            raw.updated_at
        );
        this.media = raw;
    }

    get formats(): { [format: string]: StrapiMediaFormat } {
        return this.media.formats || {};
    }

    get largestUrl(): string {
        return this.findFormatUrl(true);
    }

    get smallestUrl(): string {
        return this.findFormatUrl(false);
    }

    private findFormatUrl(desc: boolean): string {
        // Const formats = this.formats;

        const formatsKeys = ['large', 'medium', 'small', 'thumbnail'];
        if (!desc) formatsKeys.reverse();

        const len = formatsKeys.length;
        for (let rank = 0; rank < len; rank++) {
            const key = formatsKeys[rank];

            // If (key in formats) return formats[key].url;
        }

        return this.media?.url || '';
    }

}
