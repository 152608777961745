
import { defineComponent, Ref, ref } from 'vue';

interface dataI {
    firstName: Ref<string>;
    lastName: Ref<string>;
    institution: Ref<string>;
    position: Ref<string>;
    street: Ref<string>;
    postalCode: Ref<string>;
    city: Ref<string>;
    country: Ref<string>;
    email: Ref<string>;
    password: Ref<string>;
    passwordBis: Ref<string>;
    showPassword: Ref<boolean>;
    errorMsg: Ref<string>;
    hasAcceptedDataPrivacy: Ref<boolean>;
}
export default defineComponent({
    components: {
    },
    setup(): dataI {
        return {
            firstName: ref(''),
            lastName: ref(''),
            institution: ref(''),
            position: ref(''),
            street: ref(''),
            postalCode: ref(''),
            city: ref(''),
            country: ref(''),
            email: ref(''),
            password: ref(''),
            passwordBis: ref(''),
            showPassword: ref(false),
            errorMsg: ref(''),
            hasAcceptedDataPrivacy: ref(false)
        };
    },
    methods: {
        toggleShowRegister() {
            this.$emit('toggleShowRegister');
        },
        async tryRegister() {
            if (!this.validateFields()) {
                return;
            }

            const currentDate = new Date();
            const res = await fetch(process.env.VUE_APP_API_URL + '/auth/local/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    institution: this.institution,
                    position: this.position,
                    street: this.street,
                    postalCode: this.postalCode,
                    city: this.city,
                    country: this.country,
                    email: this.email,
                    password: this.password,
                    RGPD: {
                        hasAccepted: this.hasAcceptedDataPrivacy,
                        acceptedAt: currentDate
                    }
                })
            });
            const json = await res.json();

            if (json.error) {
                switch (json.error.status) {
                    case 400:
                        this.errorMsg = this.$t('Login.ErrorHandling.EmailAlreadyRegistered');
                        break;
                    default:
                        this.errorMsg = 'Unknown error';
                        break;
                }
            } else {
                this.$emit('registered');
            }
        },
        validateFields() {

            // If password doesn't match regexp
            if (!this.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/)) {
                this.errorMsg = this.$t('Login.ErrorHandling.PasswordRegexp');
                window.scrollTo(500, 500);
                return false;
            }

            // If password and passwordBis are not equal
            if (this.password !== this.passwordBis) {
                this.errorMsg = this.$t('Login.ErrorHandling.PasswordMismatch');
                window.scrollTo(500, 500);
                return false;
            }

            // If email is not valid
            if (!this.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errorMsg = this.$t('Login.ErrorHandling.EmailRegexp');
                window.scrollTo(500, 500);
                return false;
            }

            // If any field is empty
            if (this.firstName === '' || this.lastName === '' || this.institution === '' || this.position === '' || this.street === '' || this.postalCode === '' || this.city === '' || this.country === '' || this.email === '' || this.password === '' || this.passwordBis === '') {
                this.errorMsg = this.$t('Login.ErrorHandling.EmptyFields');
                window.scrollTo(500, 500);
                return false;
            }

            // If data privacy checkbox is not checked
            if (!this.hasAcceptedDataPrivacy) {
                this.errorMsg = this.$t('Login.ErrorHandling.DataPrivacy');
                window.scrollTo(500, 500);
                return false;
            }

            this.errorMsg = '';
            window.scrollTo(500, 500);
            return true;
        }
    }
});
