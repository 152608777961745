/* eslint-disable no-undefined */
import { currentJwt, authReady } from '@/utils/auth';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';
import { extractLocalizedId } from './helper/locale';
import { ICompatibilityData, IConversation, IMedicin, IRevision, IUser, compatibilityFrom, compatibilityToJSON, fromDataType } from './helper/interface';
import { TransportSolution } from './transports';
import { Media } from './helper/media';
import CompatibilityRequest from './compatibility-request';

export class CompatibilityPlan {

    id: string | undefined;
    status: string = '';
    reviewerEditor: IUser;
    reviewerLaboratory: IUser | undefined;
    conversation: IConversation[] = [];
    revisions: IRevision[] = [];
    compatibility: ICompatibilityData;
    testSchedule: Media | undefined;
    testReports: Media[] | undefined;
    compatibilityRequest: CompatibilityRequest | undefined;
    createdAt: string;
    updatedAt: string;

    constructor(
        id: number | string | null,
        status: string,
        compatibility: ICompatibilityData,
        reviewerEditor: IUser,
        reviewerLaboratory?: IUser,
        conversation?: IConversation[],
        revisions?: IRevision[],
        testSchedule?: Media,
        testReports?: Media[],
        compatibilityRequest?: CompatibilityRequest,
        createdAt?: string,
        updatedAt?: string

    ) {
        if (id !== null) this.id = String(id);
        this.status = status || this.status;
        this.compatibility = compatibility;
        this.reviewerEditor = reviewerEditor;
        this.reviewerLaboratory = reviewerLaboratory;
        this.conversation = conversation || this.conversation;
        this.revisions = revisions || this.revisions;
        this.testSchedule = testSchedule;
        this.testReports = testReports;
        this.compatibilityRequest = compatibilityRequest;
        this.createdAt = createdAt || Date.now().toString();
        this.updatedAt = updatedAt || Date.now().toString();
    }

    async save(fields?: string[]): Promise<CompatibilityPlan> {
        await authReady;
        const body = { data: this.toJSON() };
        if (fields) {
            for (const k in body.data) {
                if (fields.indexOf(k) < 0) {
                    delete body.data[k];
                }
            }
        }
        const res = await fetch(process.env.VUE_APP_API_URL + '/compatibility-plans' + (this.id ? '/' + this.id : ''), {
            headers: {
                'Authorization': 'Bearer ' + currentJwt.value,
                'Content-Type': 'application/json'
            },
            method: this.id ? 'PUT' : 'POST',
            body: JSON.stringify(body)

        });

        // Const data = await res.json();

        // Object.assign(this, CompatibilityPlan.from(data));
        return this;

    }

    toJSON() :any {
        return {
            status: this.status,
            reviewerEditor: this.reviewerEditor.id,
            reviewerLaboratory: this.reviewerLaboratory?.id,
            compatibility: compatibilityToJSON(this.compatibility),
            conversation: this.conversation.map(item => ({ ...item, date: item.date.toISOString(), author: item.author?.id || null })),
            revisions: this.revisions.map(item => ({ ...item, date: item.date.toISOString(), author: item.author?.id || null })),
            testSchedule: this.testSchedule?.id,
            testReports: this.testReports?.map(item => item.id),
            compatibilityRequest: this.compatibilityRequest?.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        };
    }

    static plans : Ref<CompatibilityPlan[]> = ref([]);

    static async init() :Promise<Ref<CompatibilityPlan[]>> {
        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/compatibility-plans?' +
        'populate[compatibility][populate]=*' +
        '&populate[compatibility][transportSolution][populate]=*' +
        '&populate[conversation][populate]=*' +
        '&populate[revisions][populate]=*' +
        '&populate[compatibilityRequest][populate]=*' +
        '&populate[testReports][populate]=*' +
        '&populate[testSchedule][populate]=*' +
        '&populate[reviewerEditor][populate]=*' +
        '&populate[reviewerLaboratory][populate]=*', {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;

        this.plans.value.splice(0);
        for (let i = 0; i < data.length; i++) {
            try {
                const rawC = fromDataType(data[i]);
                const c = CompatibilityPlan.from(rawC);
                this.plans.value.push(c);
            } catch (error) {
                console.log('error', error);
            }
        }

        return this.plans;
    }

    static from(raw: any): CompatibilityPlan {
        return new CompatibilityPlan(
            raw.id || null,
            raw.status,
            compatibilityFrom(raw.compatibility),
            raw.reviewerEditor,
            raw.reviewerLaboratory,
            raw.conversation.map((item: any) => ({ ...item, date: new Date(item.date) })),
            raw.revisions.map((item: any) => ({ ...item, date: new Date(item.date) })),
            raw.testSchedule ? Media.from(raw.testSchedule) : undefined,
            raw.testReports?.map((item: any) => Media.from(item)),
            raw.compatibilityRequest ? CompatibilityRequest.from(raw.compatibilityRequest) : undefined,
            raw.createdAt,
            raw.updatedAt
        );
    }

    async sendConversation(conv: any, reviewer: boolean = false): Promise<CompatibilityPlan> {
        const body = {
            conversation: conv,
            reviewerLaboratory: this.reviewerLaboratory ? this.reviewerLaboratory.id : conv.author
        };
        const res = await fetch(process.env.VUE_APP_API_URL + '/compatibility-plans/' + this.id + '/conversation', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem('jwt')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        return this;
    }
}


export default CompatibilityPlan;
