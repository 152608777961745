

import { defineComponent, Ref, ref } from 'vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

import MainContent from '@/components/layout/main_content.vue';
import GreenTitle from '@/components/shared/green_title.vue';
import Card from '@/components/shared/card.vue';
import EditorWizard from '@/components/requests/editor/editor_wizard.vue';
import PruferWizard from '@/components/requests/prufer/prufer_wizard.vue';

import Profile from '@/components/requests/shared/profile.vue';

import CompatibilityRequest from '@/model/compatibility-request';
import CompatibilityPlan from '@/model/compatibility-plan';
import { getMe } from '@/utils/auth';


interface dataI {
    test: Ref<String>,
    chosenTab: Ref<number>,
    requests: Ref<CompatibilityRequest[]>,
    plans: Ref<CompatibilityPlan[]>,
    currentUser: Ref<{ id: number, username: string, email: string, provider: string, role: { type: string }, confirmed: boolean, createdAt: string}>,
    isReviewer: Ref<boolean>,
    intervalId: Ref<ReturnType<typeof setTimeout>|null>
}
export default defineComponent({
    components: {
        MainContent,
        GreenTitle,
        Card,
        EditorWizard,
        PruferWizard,
        Profile
    },
    setup(): dataI {
        return {
            test: ref(''),
            chosenTab: ref(0),
            requests: ref([]),
            plans: ref([]),
            currentUser: ref({ id: 0, username: '', email: '', provider: '', role: { type: '' }, confirmed: false, createdAt: '' }),
            isReviewer: ref(Boolean(window.localStorage.getItem('reviewer'))) || false,
            intervalId: ref(null)
        };
    },
    methods: {
        updateSubNavBar(crumbs: [{ title: string; path: string; }]) {
            setBreadcrumbs(crumbs);
            if (this.$parent != null) {
                this.$parent.$emit('updateTitles', 'Compatibility.CompatibilityRequest.Title', this.currentUser.role.type === 'editor' ? 'Compatibility.CompatibilityRequest.Editor' : 'Compatibility.CompatibilityRequest.LabTester');
            }
        },
        async changeTabs(n: number) {
            await this.retrieveRequests();
            const crumb = {
                title: n === 0
                    ? 'hide'
                    : (
                        n === 1
                            ? 'Compatibility.CompatibilityRequest.Title'
                            : (
                                'Profile.Title'
                            )
                    ),
                path: '/requests'
            };

            this.chosenTab = n;
            this.updateSubNavBar([crumb]);
        },
        async retrieveRequests() {
            CompatibilityRequest.init().then((requests: Ref<CompatibilityRequest[]>) => {
                this.requests = requests.value.filter((request: CompatibilityRequest) => request.status === 'new');
            });

            CompatibilityPlan.init().then((plans: Ref<CompatibilityPlan[]>) => {
                this.plans = plans.value;
            });
        },
        async retrieveUser() {
            this.currentUser = await getMe();
            this.updateSubNavBar([{ title: 'hide', path: '' }]);
        }
    },
    beforeMount: async function() {
        this.retrieveUser();
        this.retrieveRequests();
        this.intervalId = setInterval(() => {
            if (this.isReviewer) {
                this.retrieveRequests();
            }
        }, 60000);
    },
    beforeUnmount: function() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
        }
    }
});
